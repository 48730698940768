import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Product, ProductService } from '@services';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { IframeDialogComponent, TrackByItem } from '@components';
import { ProductHelpFilterLabel } from '@enums';
import { firstValueFrom, map, Subscription } from 'rxjs';
import { ProductHelpService } from '../product-help.service';
import { ProductHelpArticle } from './product-help-article.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-product-help-article',
    templateUrl: './product-help-article.component.html',
    styleUrls: ['./product-help-article.component.css'],
})
export class ProductHelpArticleComponent implements OnInit, OnDestroy, TrackByItem<ProductHelpArticle> {
    @Input() label: ProductHelpFilterLabel;
    private subs: Subscription[] = [];
    articles: ProductHelpArticle[] = [];
    title = ''; // TODO: Is this even used?
    isMobileViewport = false;

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private productHelpService: ProductHelpService,
        private productService: ProductService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.productService.selectedProduct$.subscribe((product: Product) => {
                this.subs.push(
                    this.productHelpService.getArticles(product).subscribe((articleItems: ProductHelpArticle[]) => {
                        this.articles = articleItems;
                        this.changeDetector.markForCheck();
                    }),
                );
            }),
        );

        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((state: BreakpointState) => {
                this.isMobileViewport = state.matches;
                this.changeDetector.markForCheck();
            }),
        );
    }

    /**
     * Open MUI Modal Dialog with ProductHelpArticle data
     * @param link - ProductHelpArticle
     */
    handleOpenDialog(article: ProductHelpArticle) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: article?.title?.rendered,
                    src: article?.link + '?theme=simplified',
                    dataAnalytics: 'product-help-article',
                },
            });
        });
    }

    /**
     * Track by ProductHelpArticle
     * @param _index - number
     * @param item - ProductHelpArticle
     * @returns number or string unique identifier
     */
    trackByItem(_index: number, item: ProductHelpArticle): NonNullable<number | string> {
        if (item.id) {
            return item.id;
        } else {
            return _index;
        }
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    /**
     * Formats the given rendered excerpt text to a plain string. The render
     * ends with "... [More]", where "More" is a hyperlink. This function
     * slices the given excerpt up to the unicode ellipsis "\u2026" (exclusive).
     *
     * @param excerpt to format
     * @returns the formatted plain-text
     */
    formatExcerpt(excerpt: string): string {
        const div = this.document.createElement('div');
        div.innerHTML = excerpt;
        const text = div.textContent || div.innerText || '';
        if (text.includes('\u2026')) {
            return text.slice(0, text.lastIndexOf('\u2026'));
        } else {
            return text;
        }
    }
}
