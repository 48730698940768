import { Component, Input } from '@angular/core';
import { GknFeaturedContent } from '@services';
import { firstValueFrom, map } from 'rxjs';
import { IframeDialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-featured-content-video-embed',
    templateUrl: './featured-content-video-embed.component.html',
    styleUrls: ['./featured-content-video-embed.component.css'],
})
export class FeaturedContentVideoEmbedComponent {
    @Input() featuredContent: GknFeaturedContent;
    constructor(private dialog: MatDialog, private breakpointObserver: BreakpointObserver) {}

    /**
     * Open MUI Modal Dialog with GknFeaturedContent data
     * @param link - GknFeaturedContent
     */
    handleOpenDialog(link: GknFeaturedContent) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: link?.title,
                    src: link?.url,
                    dataAnalytics: 'featured-content-video',
                },
            });
        });
    }
}
