import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TrackByItem } from '@components';
import { FavoriteId, StaticFavoriteRoutePath } from '@enums';
import { AuthService, ContentService, FavoritesService, Product, ProductService, UserService } from '@services';
import { Observable, catchError, combineLatestWith, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { FavoriteAttributes } from 'src/app/@shared/services/favorites/types';

import { SectionScrollService } from 'src/app/@shared/services/section-scroll/section-scroll.service';
import {
    ConnectWithGenesysTile,
    ConnectWithGenesysTileThumbnails,
} from 'src/app/get-involved/connect-with-genesys/connect-with-genesys-tile.interface';
import { connectWithGenesysTileById } from 'src/app/get-involved/connect-with-genesys/connect-with-genesys.data';
import { FavoriteElementMeta } from './types';

@Component({
    selector: 'app-my-favorites',
    templateUrl: './my-favorites.component.html',
    styleUrls: ['./my-favorites.component.css'],
})
export class MyFavoritesComponent implements OnInit, TrackByItem<FavoriteElementMeta> {
    selectedProduct$: Observable<Product>;
    isAuthenticated$: Observable<boolean>;
    favorites$: Observable<FavoriteElementMeta[] | FavoriteElementMeta[][]>;
    isMobileViewport$: Observable<boolean>;
    sectionLocation = FavoriteId.MY_FAVORITES;

    constructor(
        private authService: AuthService,
        private breakpointObserver: BreakpointObserver,
        private contentService: ContentService,
        private productService: ProductService,
        private sectionScrollService: SectionScrollService,
        private userService: UserService,
        private favoritesService: FavoritesService,
    ) {}

    ngOnInit(): void {
        this.selectedProduct$ = this.productService.selectedProduct$;
        this.isAuthenticated$ = this.userService.isAuthenticated$;

        this.isMobileViewport$ = this.breakpointObserver
            .observe('(max-width: 1020px)')
            .pipe(map((state: BreakpointState) => state.matches));

        this.favorites$ = this.isMobileViewport$.pipe(
            // make sure to subscribe to mobile viewport first so that it can be observed later in this pipe but not block fetching from the server
            switchMap(() => this.favoritesService.favorites$),
            combineLatestWith(this.selectedProduct$),
            switchMap(([favorites, selectedProduct]): Observable<FavoriteElementMeta[]> => {
                return this.adapt(favorites, selectedProduct);
            }),
            map((favorites) => {
                const sorted = favorites.sort((a, b) => a?.label?.localeCompare(b?.label));
                return sorted;
            }),
            combineLatestWith(this.isMobileViewport$),
            map(([favorites, isMobileViewport]): FavoriteElementMeta[] | FavoriteElementMeta[][] => {
                if (isMobileViewport) {
                    const carouselChunks: FavoriteElementMeta[][] = [];
                    const pageSize = 6;
                    for (let i = 0; i < favorites.length; i += pageSize) {
                        carouselChunks.push(favorites.slice(i, i + pageSize));
                    }
                    return carouselChunks;
                } else {
                    return favorites;
                }
            }),
            tap((favorites) => {
                console.debug('favorites', favorites);
            }),
        );
    }

    // TODO Move to FavoriteService
    private adapt(favorites: FavoriteAttributes[], selectedProduct: Product): Observable<FavoriteElementMeta[]> {
        const arr = favorites
            .filter((favorite) => !!favorite.favoriteId) // KNOW-2313 Although we no longer assert against this favorite being in favorite-id.enum.ts, we still need to ensure the favorite ID is defined. Previous bugs allowed for undefined favorite IDs to make it to production.
            .map((favorite: FavoriteAttributes): Observable<FavoriteElementMeta> => {
                const obs: Observable<FavoriteElementMeta> = favorite.favoriteId.startsWith('owtgi')
                    ? of(connectWithGenesysTileById(favorite.favoriteId)).pipe(
                          filter((tile) => !!tile), // KNOW-1800 Handle an old favorite ID that has since been removed from Strapi
                          map((tile: ConnectWithGenesysTile) => {
                              const favoriteMeta = {
                                  id: favorite.favoriteId,
                                  label: tile.title,
                                  urlPath: tile.url,
                                  attributes: favorite,
                                  thumbnails: tile.thumbnails,
                                  fragment: this.favoritesService.buildFragmentFromFavorite(favorite.favoriteId),
                              };
                              return favoriteMeta;
                          }),
                          catchError((err) => {
                              console.error(err);
                              return of(null);
                          }),
                      )
                    : of({
                          id: favorite.favoriteId,
                          label: this.favoritesService.mapToLabel(favorite.favoriteId, selectedProduct),
                          urlPath: `/${StaticFavoriteRoutePath[favorite.favoriteId]}`,
                          attributes: favorite,
                          thumbnails: this.mapToThumbnails(favorite.favoriteId),
                          fragment: this.favoritesService.buildFragmentFromFavorite(favorite.favoriteId),
                      });

                return obs;
            });

        return arr.length > 0 ? forkJoin(arr) : of([]);
    }
    /**
     * @returns a thumbnails object.
     */
    private mapToThumbnails(favoriteId: FavoriteId): ConnectWithGenesysTileThumbnails {
        switch (favoriteId) {
            case FavoriteId.APP_FOUNDRY:
                return {
                    web: '/assets/images/favorites/AppFoundry-desktop.png',
                    mobileFavorite: '/assets/images/favorites/AppFoundry-mobile.png',
                };
            case FavoriteId.ARCHIVES:
                return {
                    web: '/assets/images/favorites/Archives-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Archives-mobile.png',
                };
            case FavoriteId.BEYOND:
                return {
                    web: '/assets/images/favorites/Beyond-Training-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Beyond-Training-mobile.png',
                };
            case FavoriteId.CERTIFICATIONS_GET_CERTIFIED:
                return {
                    web: '/assets/images/favorites/Certifications-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Certifications-mobile.png',
                };
            // case FavoriteId.CS_ACADEMY:
            //     return {
            //         web: '/assets/images/favorites/CS-Academy-Training-desktop.png',
            //         mobileFavorite: '/assets/images/favorites/CS-Academy-Training-mobile.png',
            //     };
            case FavoriteId.CERTIFICATIONS_MY_AWARDS:
                return {
                    web: '/assets/images/favorites/My-Awards-desktop.png',
                    mobileFavorite: '/assets/images/favorites/My-Awards-mobile.png',
                };
            case FavoriteId.COMMUNITY_QA_SHOW:
                return {
                    web: '/assets/images/favorites/Community-Q&A-Show-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Community-Q&A-Show-mobile.png',
                };
            case FavoriteId.CUSTOMER_SUCCESS:
                return {
                    web: '/assets/images/favorites/Customer-Success-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Customer-Success-mobile.png',
                };
            case FavoriteId.CX_EVOLUTION:
                return {
                    web: '/assets/images/favorites/CX-Evolution-desktop.png',
                    mobileFavorite: '/assets/images/favorites/CX-Evolution-mobile.png',
                };
            case FavoriteId.CX_EVOLUTION_CONNECT:
                return {
                    web: '/assets/images/favorites/CX-Evolution-Connect-desktop.png',
                    mobileFavorite: '/assets/images/favorites/CX-Evolution-Connect-mobile.png',
                };
            case FavoriteId.CX_EVOLUTION_GET_STARTED:
                return {
                    web: '/assets/images/favorites/CX-Evolution-Get-Started-desktop.png',
                    mobileFavorite: '/assets/images/favorites/CX-Evolution-Get-Started-mobile.png',
                };
            case FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS:
                return {
                    web: '/assets/images/favorites/CX-Evolution-Learn-from-Others-desktop.png',
                    mobileFavorite: '/assets/images/favorites/CX-Evolution-Learn-from-Others-mobile.png',
                };
            case FavoriteId.DEVELOPER_CENTER_TOOLS:
                return {
                    web: '/assets/images/favorites/Developer-Center-Portal-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Developer-Center-Portal-mobile.png',
                };
            case FavoriteId.DEVELOPER_CENTER_POPULAR:
                return {
                    web: '/assets/images/favorites/Developer-Center-or-Portal-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Developer-or-Center-Portal-mobile.png',
                };
            case FavoriteId.DEVELOPER_PORTAL_PURECONNECT:
                return {
                    web: '/assets/images/favorites/Developer-Center-or-Portal-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Developer-Center-Portal-mobile.png',
                };
            case FavoriteId.EXPERT_APPS:
                return {
                    web: '/assets/images/favorites/Expert-Apps-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Expert-Apps-mobile.png',
                };
            case FavoriteId.GET_STARTED:
                return {
                    web: '/assets/images/favorites/Get-Started-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-mobile.png',
                };
            case FavoriteId.MARKETING_EVENTS:
                return {
                    web: '/assets/images/favorites/Events-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Events-mobile.png',
                };
            case FavoriteId.LATEST_DISCUSSIONS:
                return {
                    web: '/assets/images/favorites/Latest-Discussions-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Latest-Discussions-mobile.png',
                };
            case FavoriteId.NEWS_FROM_GENESYS_ALL:
                return {
                    web: '/assets/images/favorites/News-Genesys-desktop.png',
                    mobileFavorite: '/assets/images/favorites/News-Genesys-mobile.png',
                };
            case FavoriteId.PRODUCT_FUNDAMENTALS:
                return {
                    web: '/assets/images/favorites/Product-Fundamentals-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Fundamentals-mobile.png',
                };
            case FavoriteId.PRODUCT_HELP_ARTICLES:
                return {
                    web: '/assets/images/favorites/Product-Help-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Help-mobile.png',
                };
            case FavoriteId.PRODUCT_SUPPORT_CENTER:
                return {
                    web: '/assets/images/favorites/Product-Support-Center-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Support-Center-mobile.png',
                };
            case FavoriteId.RELATED_COMMUNITIES:
                return {
                    web: '/assets/images/favorites/Related-Communities-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Related-Communities-mobile.png',
                };
            case FavoriteId.SUPPORT_CASES:
                return {
                    web: '/assets/images/favorites/My-Cases-desktop.png',
                    mobileFavorite: '/assets/images/favorites/My-Cases-mobile.png',
                };
            case FavoriteId.MY_CONTACTS:
                return {
                    web: '/assets/images/favorites/My-Contacts-desktop.png',
                    mobileFavorite: '/assets/images/favorites/My-Contacts-mobile.png',
                };
            case FavoriteId.EDU_EVENTS_AND_WEBINARS:
                return {
                    web: '/assets/images/favorites/Webinars-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Write-Review-mobile.png',
                };
            case FavoriteId.PRODUCT_HELP_VIDEOS: {
                return {
                    web: '/assets/images/favorites/Product-Help-Videos-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Help-Videos-mobile.png',
                };
            }
            case FavoriteId.PRODUCT_HELP_USE_CASES: {
                return {
                    web: '/assets/images/favorites/Product-Help-Use-Cases-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Help-Use-Cases-mobile.png',
                };
            }
            case FavoriteId.PRODUCT_HELP_DOCUMENTATION: {
                return {
                    web: '/assets/images/favorites/Product-Help-Articles-or-Documentation-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Product-Help-Articles-or-Documentation-mobile.png',
                };
            }
            case FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS: {
                return {
                    web: '/assets/images/favorites/News-from-Genesys-Announcements-desktop.png',
                    mobileFavorite: '/assets/images/favorites/News-from-Genesys-Announcements-mobile.png',
                };
            }
            case FavoriteId.NEWS_FROM_GENESYS_BLOG: {
                return {
                    web: '/assets/images/favorites/News-from-Genesys-Blog-desktop.png',
                    mobileFavorite: '/assets/images/favorites/News-from-Genesys-Blog-mobile.png',
                };
            }
            case FavoriteId.NEWS_FROM_GENESYS_SOCIAL: {
                return {
                    web: '/assets/images/favorites/News-from-Genesys-Social-desktop.png',
                    mobileFavorite: '/assets/images/favorites/News-from-Genesys-Social-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_ONBOARDING: {
                return {
                    web: '/assets/images/favorites/Get-Started-Onboarding-Webinars-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-Onboarding-Webinars-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_SHORT_TRACKS: {
                return {
                    web: '/assets/images/favorites/Get-Started-Short-Tracks-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-Short-Tracks-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_STARTER_KIT: {
                return {
                    web: '/assets/images/favorites/Get-Started-Starter-Kit-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-Starter-Kit-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_TIPS_AND_TOOLS: {
                return {
                    web: '/assets/images/favorites/Get-Started-Tips-and-Tools-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-Tips-and-Tools-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_USEFUL_LINKS: {
                return {
                    web: '/assets/images/favorites/Get-Started-Useful-Links-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Get-Started-Useful-Links-mobile.png',
                };
            }
            case FavoriteId.DEVELOPER_CENTER_BLOG: {
                return {
                    web: '/assets/images/favorites/Developer-Center-Blog-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Developer-Center-Blog-mobile.png',
                };
            }
            case FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS: {
                return {
                    web: '/assets/images/favorites/Developer-Center-Popular-Topics-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Developer-Center-Popular-Topics-mobile.png',
                };
            }
            case FavoriteId.RECOMMENDATIONS: {
                return {
                    web: '/assets/images/favorites/Recommendations-desktop.png',
                    mobileFavorite: '/assets/images/favorites/Recommendations-mobile.png',
                };
            }
            case FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO: {
                return {
                    web: '/assets/images/favorites/My-Personalized-Video-desktop.png',
                    mobileFavorite: '/assets/images/favorites/My-Personalized-Video-mobile.png',
                };
            }
            /*
             * Some favorite IDs cannot be favorited but this function's intent is to
             * never actually get to the default case (covered by existing unit tests),
             * so return _any_ non-empty string
             */
            case FavoriteId.JUST_FOR_ME: // not a real favoritable section
            // fall-through
            case FavoriteId.ADDITIONAL_SITES: // not a real favoritable section
            // fall-through
            case FavoriteId.MY_FAVORITES: // not a real favoritable section
                return {
                    web: '/assets/images/favorites/My-Personalized-Video-desktop.png',
                    mobileFavorite: '/assets/images/favorites/My-Personalized-Video-mobile.png',
                };
            default:
                console.error(`Thumbnails not defined for static favorite section: ${favoriteId}`);
                return {
                    web: '',
                    mobileFavorite: '',
                };
        }
    }

    trackByItem(_index: number, item: FavoriteElementMeta): NonNullable<number | string> {
        return item.attributes.favoriteId;
    }

    scroll(): void {
        this.sectionScrollService.checkForScroll();
    }

    openLoginModal(): void {
        this.authService.emitOpenLoginModal();
    }
}
