import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupportOutageAlert } from './support-outage-banner.interface';

@Injectable()
export class SupportOutageBannerService {
    constructor(private http: HttpClient) {}

    get supportOutageAlert$(): Observable<SupportOutageAlert> {
        return this.http.get<SupportOutageAlert>('https://status.mypurecloud.com/api/v2/summary.json');
    }
}
