import { Marker, Overlay, OverlayHtmlElement, Typography } from '../../components';

export class RelaxOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 4.9, text: 'Relax' },
        end: { time: 9.1, text: 'Relax end' },
    };
    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutLeftBig' });
    }

    html(): HTMLElement {
        const topText = new Typography({
            text: 'Sit back and relax.',
            fontFamily: 'Roboto',
            fontSize: this.width * 0.04 + 'px',
            lineHeight: '0',
        }).html();

        const botText = new Typography({
            text: "We'll summarize at the end.",
            fontSize: this.width * 0.025 + 'px',
            lineHeight: '0',
        }).html();

        return new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            childNodes: [topText, botText],
            hidden: true,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40%',
            top: '25%',
            opacity: '.95',
        });
    }
}
