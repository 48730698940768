import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService, UserService } from '@services';
import { Subject, Subscription } from 'rxjs';

import { BreakpointObserver } from '@angular/cdk/layout';
import { StarterKitTab } from '../starter-kit.interface';

@Component({
    selector: 'app-starter-kit-tab',
    templateUrl: './starter-kit-tab.component.html',
    styleUrls: ['./starter-kit-tab.component.css'],
})
export class StarterKitTabComponent implements OnInit, OnDestroy {
    @Input() tab: StarterKitTab;
    isAuthenticated = false;
    isMobile = false;
    selectedStepIndex = -1;
    hasCompletedStarterKit = false;

    @Input() updateToLatestStep$: Subject<boolean>;

    private subs: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            }),
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((state) => {
                this.isMobile = state.matches;
            }),
        );
    }

    login() {
        this.authService.emitOpenLoginModal();
    }

    updateParagraph(done: boolean) {
        this.hasCompletedStarterKit = done;
    }

    completed(selectedStepIndex: number): void {
        this.tab.steps[selectedStepIndex].completed = true;
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }
}
