import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { VideoContainerModule } from '../../video-container/video-container.module';
import { GenericVideoDialogComponent } from './generic-video-dialog.component';

@NgModule({
    declarations: [GenericVideoDialogComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule, MatIconModule, VideoContainerModule],
    exports: [GenericVideoDialogComponent],
})
export class GenericVideoDialogModule {}
