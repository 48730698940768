import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Angular Material dialog wrapper for our properietary VideoContainerComponent
 *
 * @example
 * handleOpenDialog(url: string): void {
 *   this.dialog.open(GenericVideoDialogComponent, {
 *     data: {
 *       title: 'my video',
         url: url
 *     },
 *   });
 * }
 */
@Component({
    selector: 'app-generic-video-dialog',
    templateUrl: './generic-video-dialog.component.html',
    styleUrls: ['./generic-video-dialog.component.css'],
})
export class GenericVideoDialogComponent {
    colors = {
        close: 'primary',
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
