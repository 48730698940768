<div class="support-outage-layout" *ngIf="supportOutageAlert$ | async">
    <img src="/assets/icons/support/alerts-outage-site-down-time.svg" />
    <span>
        Genesys Cloud Conversation Failures: {{ timeZone }}.
        <button *desktop mat-stroked-button (click)="handleOpenDialog()">
            {{ statusPageCta }}
        </button>
        <a *mobile mat-stroked-button [href]="statusPageUrl" target="_blank">{{ statusPageCta }}</a>
    </span>
    <button mat-mini-fab (click)="closeAlert()" aria-label="Close">
        <mat-icon color="primary">close</mat-icon>
    </button>
</div>
