<ng-container *ngIf="videos.length > 0; else isLoading">
    <app-carousel
        *ngIf="desktopVideos.length > 0 && !isMobileView"
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="desktopVideos"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="productVideos">
    </app-carousel>
    <app-mobile-carousel
        *ngIf="isMobileView"
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="videos"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="productVideos">
    </app-mobile-carousel>
</ng-container>

<ng-template #productVideos let-video="$context">
    <app-tile-vertical
        [title]="video.title"
        [titleLink]="true"
        [url]="baseUrl + '/' + video.video_id"
        [description]="video?.description"
        [urlLabel]="urlLabel"
        [icon]="'/assets/icons/content-tags-video.svg'"
        [details]="'VIDEO' + ' | ' + (video?.upload_date | date: 'mediumDate')"
        [border]="false"
        [isCustomTitleLink]="true"
        [isCustomFooterLink]="true"
        [videoUrl]="true">
        <div customTitleLink>
            <!--NOTE: the #{{videoId}} remembers your last position if you scroll-->
            <button
                class="
                    button-link
                    cursor
                    primary-link
                    ellipsis-2
                    sub-title-3
                    text-brand-navy
                    margin-top-small margin-bottom-smaller
                "
                (click)="handleOpenDialog(video)"
                [innerHTML]="video?.title">
            </button>
        </div>
        <div customFooterLink>
            <div class="external-link-div">
                <button class="button-link cursor link-n" (click)="handleOpenDialog(video)"> Watch </button>
                <div class="external-icons">
                    <app-share [link]="baseUrl + '/' + video.video_id" [isCarousel]="true"></app-share>
                    <app-external-url [url]="baseUrl + '/' + video.video_id"></app-external-url>
                </div>
            </div>
        </div>
    </app-tile-vertical>
</ng-template>

<ng-template #isLoading>
    <app-loading-spinner></app-loading-spinner>
</ng-template>
