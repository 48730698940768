import { Component, OnInit } from '@angular/core';
import { FavoriteId, HomeSectionLabel, ProductUid } from '@enums';
import { ContentService, GknFeaturedContent, ProductService, UserService } from '@services';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    myFavoritesLabel = HomeSectionLabel.MY_FAVORITES;
    myFavoritesLocation = FavoriteId.MY_FAVORITES;
    isAuthenticated$: Observable<boolean>;
    featuredContent$: Observable<GknFeaturedContent>;
    showFundamentals$: Observable<boolean>;

    constructor(
        private contentService: ContentService,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.isAuthenticated$ = this.userService.isAuthenticated$;
        this.featuredContent$ = this.contentService.featuredContent$;
        this.showFundamentals$ = this.productService.selectedProduct$.pipe(
            map((product) => {
                return product.uid !== ProductUid.POINTILLIST;
            }),
        );
    }
}
