<ng-container *ngIf="shortTracks.length > 0">
    <app-carousel
        *ngIf="desktopShortTracks.length > 0 && !isMobileView"
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="desktopShortTracks"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="shortTrackTile">
    </app-carousel>
    <app-mobile-carousel
        *ngIf="isMobileView"
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="shortTracks"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="shortTrackTile">
    </app-mobile-carousel>
</ng-container>

<ng-template #shortTrackTile let-shortTrack="$context">
    <app-tile-vertical
        [title]="shortTrack?.title"
        [description]="shortTrack?.description"
        [titleLink]="true"
        [url]="shortTrack?.url"
        urlLabel="Watch"
        [icon]="'/assets/icons/content-tags-video.svg'"
        [details]="'VIDEO' + ' | ' + (shortTrack?.uploadDate | date: 'mediumDate')"
        [border]="false"
        [isCustomTitleLink]="true"
        [isCustomFooterLink]="true"
        [videoUrl]="true"
        [dataAnalytics]="shortTrack?.dataAnalytics">
        <div customTitleLink>
            <!--NOTE: the #{{videoId}} remembers your last position if you scroll-->
            <button
                class="
                    button-link
                    cursor
                    primary-link
                    ellipsis-2
                    sub-title-3
                    text-brand-navy
                    margin-top-small margin-bottom-smaller
                "
                (click)="handleOpenDialog(shortTrack)"
                [attr.data-analytics]="getModalDataAnalytics(shortTrack?.dataAnalytics)"
                [innerHTML]="shortTrack?.title">
            </button>
        </div>
        <div customFooterLink>
            <div class="external-link-div">
                <button
                    class="button-link cursor link-n"
                    (click)="handleOpenDialog(shortTrack)"
                    [attr.data-analytics]="getModalDataAnalytics(shortTrack?.dataAnalytics)">
                    Watch
                </button>
                <div class="external-icons">
                    <app-share [link]="shortTrack?.url"></app-share>
                    <app-external-url [url]="shortTrack?.url"></app-external-url>
                </div>
            </div>
        </div>
    </app-tile-vertical>
</ng-template>
