import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { KeplerVimeoResponse, KeplerVimeoVideo } from 'src/app/@shared/services/kepler-vimeo/kepler-vimeo.interface';

import { environment } from '@environments/environment';
import { firstValueFrom, map, ReplaySubject, Subscription } from 'rxjs';
import { KeplerVimeoService } from 'src/app/@shared/services/kepler-vimeo/kepler-vimeo.service';
import { ShortTrack } from './short-track.interface';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';

@Component({
    selector: 'app-short-tracks',
    templateUrl: './short-tracks.component.html',
    styleUrls: ['./short-tracks.component.css'],
})
export class ShortTracksComponent implements OnInit, OnDestroy {
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);

    shortTracks: ShortTrack[] = [];
    desktopShortTracks: any[] = [];
    isCarousel: false; // TODO: Is this used?
    private subs: Subscription[] = [];
    isMobileView = false;

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        private vimeoService: KeplerVimeoService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((result: BreakpointState) => {
                this.isMobileView = result.matches;
                this.changeDetector.markForCheck();
            }),
        );

        this.vimeoService.getVideos().subscribe((vimeoResponse: KeplerVimeoResponse) => {
            this.loaded$.next(true);
            if (vimeoResponse) {
                vimeoResponse.data.data.forEach((video: KeplerVimeoVideo) => {
                    this.shortTracks.push({
                        url: `${environment.videoEmbed.vimeo}/${video.uri.replace(
                            '/videos/',
                            '',
                        )}?byline=false&portrait=false&title=false`,
                        title: video.name,
                        description: video.description,
                        uploadDate: video.created_time,
                        dataAnalytics: ['short', 'tracks'],
                    });
                });
                this.shortTracks.slice(0, 15);
                this.chunkifyShortTracks();
                this.changeDetector.markForCheck();
            }
        });
    }

    /**
     * Breaks this array into sections
     */
    chunkifyShortTracks(): void {
        const carouselChunks = [];
        const pageSize = 3;

        for (let i = 0; i < this.shortTracks.length; i += pageSize) {
            carouselChunks.push(this.shortTracks.slice(i, i + pageSize));
        }

        const pageSizeLeftover = this.shortTracks.length % pageSize;
        if (pageSizeLeftover !== 0) {
            for (let i = 0; i < pageSize - pageSizeLeftover; i++) {
                carouselChunks[carouselChunks.length - 1].push(null);
            }
        }

        this.desktopShortTracks = carouselChunks;
    }

    /**
     * Open MUI Modal Dialog with ShortTrack data
     * @param link - ShortTrack
     */
    handleOpenDialog(video: ShortTrack) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: video?.title,
                    src: video?.url,
                    dataAnalytics: 'short-track',
                },
            });
        });
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    /**
     * Formats a string to use for setting a single QA Show's cta button data-analytics attribute
     * @param dataAnalytics specific tags for a single QA Show
     * @returns formatted data-analytics attribute value
     */
    getModalDataAnalytics(dataAnalytics: string[]): string {
        return [...(dataAnalytics || []), 'modal'].join('-');
    }
}
