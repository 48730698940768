<header mat-dialog-title [ngClass]="data.subtitlePosition === 'bottom' ? 'subtitle-bottom' : 'subtitle-right'">
    <div class="title" [ngClass]="data.subtitlePosition === 'bottom' ? 'subtitle-bottom' : 'subtitle-right'">
        <h2 class="h3">{{ data.title }}</h2>
        <!-- <mat-divider [vertical]="true"></mat-divider> -->
        <h3 class="sub-title-4 text-secondary-gray-1" *ngIf="data.subtitle">{{ data.subtitle }}</h3>
    </div>
    <div class="actions">
        <span class="text-dark-teal" *ngIf="isCopied">Copied!</span>
        <button
            mat-icon-button
            [color]="colors.copy"
            (mouseover)="colors.copy = 'accent'"
            (mouseout)="colors.copy = 'primary'"
            aria-label="Copy"
            (click)="handleCopy($event)"
            [attr.data-analytics]="dataAnalyticsCopy">
            <mat-icon>content_copy</mat-icon>
        </button>
        <a
            mat-icon-button
            [color]="colors.open"
            (mouseover)="colors.open = 'accent'"
            (mouseout)="colors.open = 'primary'"
            aria-label="Open"
            [href]="data.src"
            target="_blank"
            rel="nofollow"
            [attr.data-analytics]="dataAnalyticsOpenInNew">
            <mat-icon>open_in_new</mat-icon>
        </a>
        <button
            mat-icon-button
            mat-dialog-close
            [color]="colors.close"
            (mouseover)="colors.close = 'accent'"
            (mouseout)="colors.close = 'primary'"
            aria-label="Close"
            [attr.data-analytics]="dataAnalyticsClose">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</header>
<mat-dialog-content #dialogContent>
    <mat-divider></mat-divider>
    <div>
        <iframe [src]="data.src | safe: 'resourceUrl'" frameborder="0"></iframe>
    </div>
</mat-dialog-content>
