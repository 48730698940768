import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
})
export class SelectComponent {
    /** the text for the mat-form-field label */
    @Input() label: string;
    /** the select options */
    @Input() options: { text: string; value: string }[];
    /** the form control to bind to */
    @Input() control: FormControl;
    /** whether to allow multiple selections */
    @Input() multi = false;
}
