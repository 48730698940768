<app-section-header
    [title]="sectionLabel"
    [subTitle]="subTitle"
    [favoriteId]="favoriteId"
    [section]="favoriteId"
    [externalUrl]="externalLink"
    [anchors]="anchors"></app-section-header>

<mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" appFilterLocation>
    <mat-tab [label]="label">
        <app-product-help-article [label]="label"></app-product-help-article>
    </mat-tab>
    <mat-tab [label]="productHelpFilterLabel.VIDEOS">
        <app-product-help-videos [videosIds]="videos" [videoSource]="videoSource"></app-product-help-videos>
    </mat-tab>
    <mat-tab [label]="productHelpFilterLabel.USE_CASES">
        <app-use-cases [productFilter]="productFilter"></app-use-cases>
    </mat-tab>
</mat-tab-group>
