import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TrackByItem } from '@components';
import { environment } from '@environments/environment';
import {
    KeplerCoveoService,
    KeplerCoveoSuggestion,
    KeplerCoveoSuggestionsResponse,
    SupportRecommendationsService,
    UserService,
} from '@services';
import { BehaviorSubject, Observable, filter, firstValueFrom, map, switchMap, tap, timer } from 'rxjs';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.css'],
})
export class SearchBarComponent implements OnInit, TrackByItem<KeplerCoveoSuggestion> {
    /** indicates whether this component is being shown in a mobile viewport */
    isMobile$: Observable<boolean>;
    /** placeholder text for the {@link searchTerm} form control */
    @Input() defaultSearchPlaceHolder = 'What are you looking for?';
    /** Event emitter triggered by submitting a search */
    @Output() searchClick = new EventEmitter<string>();
    /** Event emitter triggered by clearing the text input */
    @Output() clearClick = new EventEmitter<void>();
    /** user input of a search term */
    searchTerm = new FormControl('');
    /** element ref to the {@link searchTerm} form control */
    @ViewChild('searchTermRef') searchTermRef: ElementRef;
    /** indicates whether this component is shown on the search page or in the app header */
    isSearchPage$: Observable<boolean>;
    /** search suggestions from Coveo */
    suggestions$: BehaviorSubject<KeplerCoveoSuggestion[]> = new BehaviorSubject([]);
    /** index of the selected suggestion via arrow up/down keys */
    private suggestionIndex$: BehaviorSubject<number> = new BehaviorSubject(-1);

    constructor(
        private breakpointObserver: BreakpointObserver,
        private coveoService: KeplerCoveoService,
        private supportRecommendationsService: SupportRecommendationsService,
        private ref: ElementRef,
        private router: Router,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.isMobile$ = this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state) => state.matches));

        this.isSearchPage$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                return this.router.url.includes('/search');
            }),
        );
    }

    /**
     * Emits the active search term text.
     * @param event click event from html template
     */
    handleSearch(event?: Event): void {
        event?.stopPropagation();

        let millis = 0;
        if (this.suggestionIndex$.value > -1) {
            this.selectSuggestion(this.suggestions$.value[this.suggestionIndex$.value]);
            millis = 250; // allow some time for form control to populate for better UX
        }

        this.clearSuggestions();
        this.searchTermRef.nativeElement.blur();

        const sub = timer(millis)
            .pipe(
                switchMap(() => {
                    return this.userService.isAuthenticated$;
                }),
                tap((isAuthenticated) => {
                    const search = this.searchTerm.value;
                    if (search) {
                        if (isAuthenticated) {
                            const cases$ = this.userService.cases$;
                            firstValueFrom(cases$).then((userDetail) => {
                                const found = userDetail?.find((item) => item.case == search);
                                if (found) {
                                    window.open(`${environment.mySupport.url}?case=${found.case}`, '_blank');
                                } else {
                                    this.searchClick.emit(search);
                                    this.supportRecommendationsService.add(search);
                                }
                            });
                        } else {
                            this.searchClick.emit(search);
                            this.supportRecommendationsService.add(search);
                        }
                    }
                    sub.unsubscribe(); // clean up possible memory leak
                }),
            )
            .subscribe();
    }

    /**
     * Emits the selected suggestion as a search ter
     * @param suggestion to select
     */
    searchSuggestion(suggestion: KeplerCoveoSuggestion): void {
        this.selectSuggestion(suggestion);
        this.handleSearch();
    }

    /**
     * Handles all keyup events
     * @param event key event
     */
    keyup(event: Event): void {
        event.stopPropagation();

        if (this.searchTerm.value == '') {
            this.clearSuggestions();
        } else {
            firstValueFrom(this.coveoService.suggestions$(this.searchTerm.value)).then(
                (suggestions: KeplerCoveoSuggestionsResponse) => {
                    const sorted =
                        suggestions.data?.sort((a, b) => a.executableConfidence - b.executableConfidence) || [];

                    this.suggestions$.next(sorted);
                },
            );
        }
    }

    /**
     * Handles key events for the Up arrow key
     * Increments the {@link suggestionIndex$}.
     * @param event key event
     */
    arrowUp(event: Event) {
        event.stopPropagation();
        if (this.suggestionIndex$.value == -1) {
            // set the index to the last suggestion
            this.suggestionIndex$.next(this.suggestions$.value.length - 1);
        } else if (this.suggestionIndex$.value > -1) {
            // decrement the index
            this.suggestionIndex$.next(this.suggestionIndex$.value - 1);
        }
    }

    /**
     * Handles key events for the Down arrow key
     * Decrements the {@link suggestionIndex$}.
     * @param event key event
     */
    arrowDown(event: Event) {
        event.stopPropagation();
        if (this.suggestionIndex$.value == this.suggestions$.value.length - 1) {
            // reset the index
            this.suggestionIndex$.next(-1);
        } else if (this.suggestionIndex$.value >= -1) {
            // increment the index
            this.suggestionIndex$.next(this.suggestionIndex$.value + 1);
        }
    }

    /**
     * Sets the given suggestion as the current search term.
     * Does not submit the search
     * @param suggestion to set
     */
    selectSuggestion(suggestion: KeplerCoveoSuggestion): void {
        this.searchTerm.setValue(suggestion.expression);
        this.clearSuggestions();
        this.searchTermRef.nativeElement.focus();
    }

    /**
     * Emits an empty event for clearing the active search term text.
     */
    handleClear(): void {
        this.reset();
        this.clearClick.emit(); // emit "nothing". do not emit empty string.
    }

    /**
     * Resets this component to its default state
     */
    reset(): void {
        this.searchTerm.setValue('');
        this.clearSuggestions();
    }

    private clearSuggestions(): void {
        this.suggestionIndex$.next(-1);
        this.suggestions$.next([]);
    }

    /**
     * Handles clicks outside of this component
     * Clears search suggestions
     * @param event click event
     */
    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        event.stopPropagation();
        if (!this.ref.nativeElement.contains(event.target)) {
            this.clearSuggestions();
        }
    }

    trackByItem(index: number): NonNullable<number | string> {
        return String(index);
    }
}
