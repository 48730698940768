import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '@components';
import { ProductSelectComponent } from './product-select.component';

@NgModule({
    declarations: [ProductSelectComponent],
    imports: [CommonModule, SelectModule],
    exports: [ProductSelectComponent],
})
export class ProductSelectModule {}
