import { Component, HostListener, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-info-tooltip',
    templateUrl: './info-tooltip.component.html',
    styleUrls: ['./info-tooltip.component.css'],
})
export class InfoTooltipComponent {
    @ViewChild('modal') modal;
    @ViewChild('button') button;

    @Input() isMobile: boolean;

    offsets = {
        left: {
            true: {
                modal: -5.5,
                arrow: 8,
            },
            false: {
                modal: 0,
                arrow: 8,
            },
        },
        middle: {
            true: {
                modal: -123,
                arrow: 125.5,
            },
            false: {
                modal: -53,
                arrow: 62.5,
            },
        },
        right: {
            true: {
                modal: -230,
                arrow: 233,
            },
            false: {
                modal: -225,
                arrow: 233,
            },
        },
    };

    modalLeft = -117;
    arrowLeft = 125.5;

    width: number = window.innerWidth;
    showModal = false;

    @HostListener('document:click', ['$event'])
    click(event) {
        if (!this.button.nativeElement.contains(event.target) && !this.modal?.nativeElement.contains(event.target)) {
            this.showModal = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.determineModalPosition();
    }

    determineModalPosition() {
        const pos = this.button.nativeElement.getBoundingClientRect();
        let side = 'middle';
        if (pos.left < 150) {
            side = 'left';
            this.modalLeft = 0;
            this.arrowLeft = 8;
        } else if (pos.right >= window.innerWidth - 150) {
            side = 'right';
            this.modalLeft = -225;
            this.arrowLeft = 233;
        }
        const offset = this.offsets[side][this.isMobile || false];
        this.modalLeft = offset.modal;
        this.arrowLeft = offset.arrow;
    }
}
