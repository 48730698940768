import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturedContentWysiwygModule } from '../featured-content-wysiwyg/featured-content-wysiwyg.module';
import { FeaturedContentTextOnlyComponent } from './featured-content-text-only.component';

@NgModule({
    declarations: [FeaturedContentTextOnlyComponent],
    imports: [CommonModule, FeaturedContentWysiwygModule],
    exports: [FeaturedContentTextOnlyComponent],
})
export class FeaturedContentTextOnlyModule {}
