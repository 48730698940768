import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsService } from '@services';
import { firstValueFrom, map, ReplaySubject } from 'rxjs';
import { BeyondTrainingTile } from '../beyond-training-tile.interface';
import { IframeDialogComponent } from '@components';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-beyond-video-content',
    templateUrl: './beyond-video-content.component.html',
    styleUrls: ['./beyond-video-content.component.css'],
})
export class BeyondVideoContentComponent implements OnInit, OnChanges {
    @Input() beyondTrainingTile: BeyondTrainingTile;
    subtitle$: ReplaySubject<string> = new ReplaySubject(1);

    constructor(private dialog: MatDialog, private breakpointObserver: BreakpointObserver) {}

    /**
     * Passthrough properties for tile-vertical.component.ts
     */
    @Input() bigImage: boolean;
    @Input() vertical: boolean;

    showModal = false;

    /**
     * String identifiers for analytics services to track this tile
     */
    dataAnalytics: string[];
    dataAnalyticsModal: string;

    ngOnInit(): void {
        this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        this.dataAnalytics = [
            'training',
            AnalyticsService.format(this.beyondTrainingTile.subtitle[0]), // first element is expect to always be present and contain "video", "course", "subscription", etc.
            AnalyticsService.format(this.beyondTrainingTile.title),
        ];
        this.dataAnalyticsModal = this.dataAnalytics?.join('-');
    }

    /**
     * Format sub title
     * @param val - value string array to be formated
     * @returns a concatenated pipe delimited string
     */
    private formatSubtitle(val: string[]): string {
        return val.join(' | ').trim();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.beyondTrainingTile) {
            this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        }
    }

    /**
     * Open MUI Modal Dialog with BeyondTrainingTile data
     * @param link - BeyondTrainingTile
     */
    handleOpenDialog(link: BeyondTrainingTile) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: link?.title,
                    subtitle: '',
                    src: link?.url,
                    dataAnalytics: 'beyond-training-video',
                },
            });
        });
    }
}
