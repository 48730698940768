import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, EMPTY, Subscription, tap } from 'rxjs';
import { StarterKitStep, StarterKitStepType, StarterKitTab } from '../starter-kit.interface';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericVideoDialogComponent, IframeDialogComponent } from '@components';
import { GetStartedFilterLabel } from '@enums';
import { ToastColor, ToastNotificationService, UserService } from '@services';
import { KeplerDbService } from 'src/app/@shared/services/kepler-db/kepler-db.service';

@Component({
    selector: 'app-starter-kit-step-content',
    templateUrl: './starter-kit-step-content.component.html',
    styleUrls: ['./starter-kit-step-content.component.css'],
})
export class StarterKitStepContentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() step: StarterKitStep;
    @Input() tab: StarterKitTab;
    @Input() selectedIndex: number;
    @Input() isMobile: boolean;

    @Output() navigate: EventEmitter<number> = new EventEmitter<number>();
    @Output() completed: EventEmitter<void> = new EventEmitter<void>();

    private subs: Subscription[] = [];

    dialogRef: MatDialogRef<any>;
    continueMessage = '';
    firstStep = false;
    lastStep = false;

    email = '';

    constructor(
        private dbService: KeplerDbService,
        private dialog: MatDialog,
        private toastService: ToastNotificationService,
        private userService: UserService,
    ) {}

    showCongrats() {
        this.toastService.notify({
            // TODO Add support to toasts to show a component
            color: ToastColor.INFO,
            innerHtml: `
            <h3 class="color-brand-navy">You're all set!</h3>
            <div style="display: flex; margin-top: 0.5rem">
                <img src="/assets/images/congrats-animation-starter-kit.gif" style="width: 150px; height: 150px; margin-right: 1rem" alt="Congratulations" />
                <div>
                    <p class="p-body">
                        Congratulations on completing your ${this.tab.name} Starter Kit!
                    </p>
                    <p class="p-body" style="margin-top: 0.5rem">
                        Be sure to bookmark
                        <a class="end-link" target="_blank" href="https://know.genesys.com/resources?product=genesys-cloud-cx">this page</a>
                        as well as these other great resources:
                        <a class="end-link" target="_blank" href="https://community.genesys.com/home">Genesys Community</a>,
                        <a class="end-link" target="_blank" href="https://help.mypurecloud.com/">Resource Center</a>, and
                        <a class="end-link" target="_blank" href="https://developer.genesys.cloud/">Developer Center</a>.
                    </p>
                </div>
            </div>
            `,
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.userService.claims$.subscribe((claims) => {
                this.email = claims?.email;
            }),
        );
    }

    ngOnChanges(): void {
        this.continueMessage = this.step.completed ? this.step.messages.replay : this.step.messages.continue;
        if (!this.tab.order && !this.step.completed) {
            this.continueMessage = this.continueMessage.split(' ')[0];
        }
        this.firstStep = this.selectedIndex == 0;
        this.lastStep = this.selectedIndex == this.tab.steps.length - 1;
    }

    openModal() {
        if (this.step.type == StarterKitStepType.HYPERLINK) {
            this.openLink();
            return;
        }

        if (this.step.type == StarterKitStepType.VIDEO) {
            this.dialogRef = this.dialog.open(GenericVideoDialogComponent, {
                width: '80%',
                height: '80%',
                data: {
                    title: this.step.title,
                    url: this.step.link,
                    dataAnalytics: this.step.dataAnalytics,
                },
            });
        } else if (this.step.type == StarterKitStepType.ARTICLE) {
            this.dialogRef = this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: '80%',
                data: {
                    title: GetStartedFilterLabel.STARTER_KIT,
                    subtitle: this.step.title,
                    src: this.step.link,
                    dataAnalytics: this.step.dataAnalytics,
                },
            });
        }

        if (this.dialogRef) {
            this.subs.push(
                this.dialogRef
                    .afterClosed()
                    .pipe(
                        tap(() => {
                            this.complete();
                            if (this.lastStep) {
                                this.showCongrats();
                            }
                        }),
                    )
                    .subscribe(),
            );
        }
    }

    openLink() {
        window.open(this.step.link, '_blank');
        this.complete();
    }

    complete() {
        if (!this.email) return;

        this.subs.push(
            this.dbService
                .addStarterKitProgress(this.email, {
                    productId: this.tab.id,
                    stepName: this.step.name,
                    stepId: this.step.id,
                    completed: true,
                })
                .pipe(
                    tap(() => {
                        this.completed.emit();
                    }),
                    catchError((err) => {
                        console.error(err);
                        this.toastService.notify({
                            color: ToastColor.CRITICAL,
                            innerHtml:
                                'Something went wrong saving your progress. You can still continue, but your progress will not be saved. Please contact <a href="mailto:knowledgenetwork@genesys.com">knowledgenetwork@genesys.com</a> for further assistance.',
                        });
                        this.completed.emit();
                        return EMPTY;
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }
}
