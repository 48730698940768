import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { KeplerSfdcUserDetail } from '../kepler-sfdc';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private userService: UserService) {
        if (!environment.featureFlags.pendo) {
            console.warn('Pendo is disabled by feature flag');
        }
    }

    get visitorId(): string | undefined {
        return pendo.getVisitorId();
    }

    get accountId(): string | undefined {
        return pendo.getAccountId();
    }

    /**
     * Initialize Pendo within the application. This should be called
     * as soon as possible in the app lifecycle.
     *
     * See more https://developers.pendo.io/docs/?bash#functions
     */
    initialize(): void {
        if (environment.featureFlags.pendo) {
            pendo.initialize();
        }
    }

    /**
     * Identify the given user in Pendo by email and other Salesforce data.
     * This should be called as soon as possible in the app lifecycle
     * See more https://developers.pendo.io/docs/?bash#functions
     *
     * @param email unique ID to track account across other pendo apps. The left portion of this email is hashed to remove PII.
     * @param sfdcUserDetail Salesforce user detail to track company. The Contact ID is used by Pendo to integrate with a pre-built connector to push data to Salesforce
     * @throws invalid args
     */
    async identify(email: string, sfdcUserDetail: KeplerSfdcUserDetail): Promise<void> {
        if (!email || email == '' || !sfdcUserDetail) {
            throw new Error('Invalid args');
        }

        if (environment.featureFlags.pendo) {
            const visitorId = await this.buildVisitorId(email);
            pendo.identify({
                account: {
                    id: sfdcUserDetail.Account?.Name || '',
                },
                visitor: {
                    // Visitor ID can be anything we want that can be tracked across
                    // all our sites, such as a user ID (anything unique that isn't personally identifiable).
                    // See more https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo#h_01GESMXKZM0VS25D38PQ1Q7464
                    id: visitorId,
                    salesforceContactId: this.userService.sfdcContactId,
                },
            });
        }
    }

    /**
     * Hashes the given email and prefixes it for the current runtime environment. The email domain will not be obfuscated.
     *
     * @param email to hash
     * @returns obfuscated visitor id
     */
    private async buildVisitorId(email: string): Promise<string> {
        const emailUsername = email.substring(0, email.indexOf('@'));
        const emailDomain = email.substring(email.indexOf('@'));

        const hashedEmailUsername = await this.digestMessage(emailUsername);

        // Pendo admins can filter out prefixed visitor IDs, like "DEV-11231023@genesys.com" or "UAT-11231023@genesys.com".
        // Note: Prefix should not be used in prod, but this is handled in the environment files with empty string.
        let visitorId = '';
        if (environment.pendo.userIdPrefix) {
            visitorId += environment.pendo.userIdPrefix;
            visitorId += '-';
        }
        visitorId += hashedEmailUsername + emailDomain;

        return visitorId;
    }

    /**
     * Hashes the given message with the SHA-256 algorithm.
     *
     * This function is taken directly from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
     *
     * @param message to hash
     * @returns Promise with the hashed message as a hex string
     * @throws falsy/empty message
     */
    private async digestMessage(message: string): Promise<string> {
        if (!message || message == '') {
            return Promise.reject('Invalid message');
        }

        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        return crypto.subtle
            .digest('SHA-256', msgUint8) // hash the message
            .then((hashBuffer) => {
                // ArrayBuffer to hashed hex string
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
                return hashHex;
            });
    }

    /**
     * Formats the given value for [data-analytics] attributes by
     * 1. Converting to all lowercase
     * 2. Replacing all spaces with hyphens
     * 3. Removing special characters
     *
     * @param s to format
     * @returns formatted id
     */
    static format(s: string): string | undefined {
        // ts-ignore
        try {
            return s
                .toLowerCase()
                .replace(/ +/gi, '-') // replace spaces
                .replace(/[`|.|,|~|;|:|!|@|#|$|%|^|&|*|(|)|\\|/|||[|\]|<|>|=]+/gi, ''); // remove special characters
        } catch (error) {
            console.error('Error formatting analytics id', error);
            return s;
        }
    }
}
