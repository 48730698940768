import {} from '@enums';

import { Component, OnInit } from '@angular/core';
import { DevCenterFilterLabel, FavoriteId, ResourcesSectionLabel } from '@enums';
import { environment } from '@environments/environment';
import { FavoritesService } from '@services';

@Component({
    selector: 'app-developer-center',
    templateUrl: './developer-center.component.html',
    styleUrls: ['./developer-center.component.css'],
})
export class DeveloperCenterComponent implements OnInit {
    externalUrl = environment.api.developerCenter.site.baseUrl;
    sectionLabel = ResourcesSectionLabel.DEV_CENTER;
    favoriteId = FavoriteId.DEVELOPER_CENTER_TOOLS;
    devCenterFilterLabel = DevCenterFilterLabel;

    anchors: string[];

    constructor(private favoritesService: FavoritesService) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_BLOG),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_TOOLS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_PORTAL_PURECONNECT),
        ];
    }
    ngOnInit(): void {
        this.filterUpdated(FavoriteId.DEVELOPER_CENTER_TOOLS);
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;
    }
}
