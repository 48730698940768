import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { VideosComponent } from './product-help-videos.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [VideosComponent],
    imports: [CommonModule, ComponentsModule, MatDialogModule],
    exports: [VideosComponent],
})
export class ProductHelpVideosModule {}
