import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-featured-content-wysiwyg',
    templateUrl: './featured-content-wysiwyg.component.html',
    styleUrls: ['./featured-content-wysiwyg.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FeaturedContentWysiwygComponent {
    @Input() data: string;
}
