import { EduTrainingSectionLabel, FavoriteId } from '@enums';
import { ContentService } from '@services';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { TrackByItem } from '@components';
import { GknEduEventAndWebinarCard } from '../../@shared/services/content/interfaces/gkn-education.interface';

@Component({
    selector: 'app-edu-events-and-webinars',
    templateUrl: './edu-events-and-webinars.component.html',
    styleUrls: ['./edu-events-and-webinars.component.css'],
})
export class EduEventsAndWebinarsComponent implements OnInit, TrackByItem<GknEduEventAndWebinarCard> {
    sectionLabel = EduTrainingSectionLabel.EDU_EVENTS_AND_WEBINARS;
    favoriteId = FavoriteId.EDU_EVENTS_AND_WEBINARS;
    sectionLocation = FavoriteId.EDU_EVENTS_AND_WEBINARS;
    cards$: Observable<GknEduEventAndWebinarCard[]>;
    showLoadMoreButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    maxItemsToLoad$: BehaviorSubject<number> = new BehaviorSubject(6);
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);
    cardsLength: number;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.cards$ = this.contentService.eduEventsAndWebinars$.pipe(
            tap((cards) => {
                this.cardsLength = cards?.length;
                this.loaded$.next(true);

                if (this.maxItemsToLoad$.value < cards?.length) {
                    this.showLoadMoreButton$.next(true);
                } else {
                    this.showLoadMoreButton$.next(false);
                }
            }),
        );
    }

    /**
     * Loads more tiles
     * @param event click event
     */
    loadMore(event: Event): void {
        event.stopPropagation();
        this.maxItemsToLoad$.next(this.maxItemsToLoad$.value + 6);
        this.showLoadMoreButton$.next(false);
    }

    trackByItem(_index: number, item: GknEduEventAndWebinarCard): NonNullable<number | string> {
        return item.title;
    }
}
