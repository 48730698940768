/**
 * WARNING
 *
 * Changing these labels will trickle out to several areas of the app. Proceed with caution.
 */

export enum HomeSectionLabel {
    MY_FAVORITES = 'My Favorites',
    JUST_FOR_ME = 'Just for Me',
    FUNDAMENTALS = 'Fundamentals',
    NEWS_FROM_GENESYS = 'News from Genesys',
    ARCHIVES = 'Roadmap Archives',
}

export enum JustForMeSectionLabel {
    MY_FAVORITES = 'My Favorites',
    BEYOND_TRAINING = 'Beyond Training',
    MY_AWARDS = 'My Awards',
    MY_CONTACTS = 'My Contacts',
    MY_CASES = 'My Cases',
    KNOWLEDGE_BASE = 'Knowledge Base',
    MY_PERSONALIZED_VIDEO = 'My Personalized Video',
    ADDITIONAL_SITES = 'Additional Sites',
}

export enum NewsFromGenesysFilterLabel {
    ALL = 'All',
    ANNOUNCEMENTS = 'Announcements',
    BLOG = 'Blog',
    SOCIAL = 'Social',
}

export enum CommunitySectionLabel {
    LATEST_DISCUSSIONS = 'Latest Discussions',
    QA_SHOW = 'Community Q&A Show',
    RELATED_COMMUNITIES = 'Related Communities',
}

export enum GetInvolvedSectionLabel {
    MARKETING_EVENTS = 'Virtual and Physical Events',
    CONNECT_WITH_GENESYS = 'Connect with Genesys',
}
export enum EduTrainingSectionLabel {
    BEYOND_TRAINING = 'Beyond Training',
    GEN_ED = 'GenEd Training',
    CERTIFICATIONS_AND_BADGES = 'Certifications & Badges',
    CS_ACADEMY = 'CS Academy Training',
    EDU_EVENTS_AND_WEBINARS = 'Webinars',
}

export enum EduTrainingBadgesFilterLabel {
    MY_AWARDS = 'My Awards',
    GET_CERTIFIED = 'Get Certified',
}

export enum ResourcesSectionLabel {
    GET_STARTED = 'Get Started',
    PRODUCT_HELP = 'Help', // product name will be prepended at run time
    APP_FOUNDRY = 'AppFoundry',
    CUSTOMER_SUCCESS = 'Customer Success',
    CX_EVOLUTION = 'CX Evolution',
    /** label for GC CX and GC MCX */
    DEV_CENTER = 'Developer Center',
    /** @deprecated do not use -- was used for Dev Center Genesys Engage On-prem, which has been permanently taken offline. */
    DEV_CENTER_POPULAR = 'Developer Center',
    /** Dev center for Pureconnect. */
    DEV_PORTAL_PURECONNECT = 'Developer Portal',
    EXPERT_APPS = 'Genesys Expert Apps',
}

export enum GetStartedSectionLabel {
    USEFUL_LINKS = 'Useful Links',
    TIPS_AND_TOOLS = 'Tips and Tools',
}

/**
 * These are mapped one to one with filters in Strapi
 * Do not update these without also updating them in Strapi
 */
export enum CxEvolutionFilterLabel {
    GET_STARTED = 'Get Started',
    CONNECT = 'Connect',
    LEARN_FROM_OTHERS = 'Learn from Others',
}

export enum DevCenterFilterLabel {
    TOOLS = 'Tools',
    POPULAR_TOPICS = 'Popular Topics',
    BLOGS = 'Blogs',
}

export enum GetStartedFilterLabel {
    STARTER_KIT = 'Starter Kit',
    USEFUL_LINKS = 'Useful Links',
    MY_PERSONALIZED_VIDEO = 'My Personalized Video',
    TIPS_AND_TOOLS = 'Tips and Tools',
    NEW_CUSTOMER_ONBOARDING = 'Onboarding Webinars',
    SHORT_TRACKS = 'Short Tracks',
}

export enum SearchResultsFilterLabel {
    ALL = 'All',
    DOCUMENTATION = 'Documentation',
    TRAINING = 'Training',
    DEVELOPER_CENTER = 'Developer Center',
    COMMUNITY = 'Community',
    BLOG = 'Blog',
}

export enum SearchResultsFilterTerm {
    ALL = 'all',
    DOCUMENTATION = 'resource',
    DOCS = 'docs',
    TRAINING = 'training',
    DEVELOPER_CENTER = 'developer', // developer_center is used by Genesys Cloud, developer_article used by Multicloud & Engage
    COMMUNITY = 'community',
    BLOG = 'blog',
}

export enum StarterKitTabName {
    GENESYS_CLOUD_CX = 'Genesys Cloud',
    GENESYS_CLOUD_CX_DIGITAL = 'Genesys Cloud Digital',
    GENESYS_CLOUD_WEM_EX = 'Genesys Cloud WEM/EX',
}

export enum StarterKitFilterLabel {
    GENESYS_CLOUD_CX = 'gc-cx',
    GENESYS_CLOUD_CX_DIGITAL = 'gc-cx-digital',
    GENESYS_CLOUD_WEM_EX = 'gc-wem-ex',
}

export enum SupportSectionLabel {
    MY_CASES = 'My Cases',
    PRODUCT_SUPPORT_CENTER = 'Product Support',
    MY_CONTACTS = 'My Contacts',
    RECOMMENDATIONS = 'Recommendations',
}

export enum ProductHelpFilterLabel {
    ARTICLES = 'Articles',
    DOCUMENTATION = 'Documentation',
    VIDEOS = 'Videos',
    USE_CASES = 'Use Cases',
}

export enum NewsLabel {
    PUBLISHED = 'Published',
}

export enum NewsOrigin {
    ANNOUNCEMENT = 'announcement',
    BLOG = 'blog',
    FACEBOOK = 'facebook',
}

export enum SearchResultLabel {
    SEARCHRESULT = 'searchResult',
}

export enum MyProfileLabel {
    MY_PROFILE = 'My Profile',
}

export enum LegacyGetStartedLabel {
    GET_STARTED = 'Get Started',
}

export enum GKNStoriesSectionLabel {
    GKN_STORIES = 'GKN Stories',
}

export enum RecommendationSectionLabel {
    RECOMMENDATIONS = 'Recommendations',
}
