import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from './use-case.interface';

@Injectable({
    providedIn: 'root',
})
export class UseCasesService {
    private baseUrl = 'https://all.docs.genesys.com/index.php';

    constructor(private http: HttpClient) {}

    /**
     * Observes uses cases from Genesys docs
     * @param productFilter the product to filter results by. This is not a Product UID! This string is specific to Genesys docs
     * @returns observable array of use cases
     */
    observeUseCases(productFilter: string): Observable<UseCase[]> {
        const url = new URL(this.baseUrl);
        url.searchParams.set('title', 'Special:CargoExport');
        url.searchParams.set('tables', 'SMART_Meta, SMART_UseCase, SMART_Popular,SMART_Canonical');
        url.searchParams.set(
            'join on',
            'SMART_Canonical.ID=SMART_Meta.ID,SMART_Meta.ID=SMART_UseCase.ID,SMART_UseCase.ID=SMART_Popular.Title',
        );
        url.searchParams.set(
            'fields',
            "CONCAT('https://all.docs.genesys.com/', SMART_UseCase._pageName)=url, CONCAT(SMART_Meta.Title, ' (',SMART_Meta.ID,')')=title,SMART_UseCase.UCSummary=summary, SMART_Canonical.DataSheetImageURL=image",
        );
        url.searchParams.set('where', `SMART_Popular.Offering="${productFilter}"`);
        url.searchParams.set('group by', 'SMART_Meta.ID');
        url.searchParams.set(
            'order by',
            "CONCAT ('{{server}}/',`cargo__SMART_UseCase`.`_pageName`,' ',`cargo__SMART_Meta`.`Title`, ' (',`cargo__SMART_Meta`.`ID`,')'),`cargo__SMART_Popular`.`Offering`",
        );
        url.searchParams.set('limit', '4');
        url.searchParams.set('format', 'json');
        return this.http.get<UseCase[]>(url.toString());
    }
}
