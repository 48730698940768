import { Component, Input, OnInit } from '@angular/core';
import { TrackByItem } from '@components';
import { EduTrainingBadgesFilterLabel, FavoriteId, ProductUid } from '@enums';

import { Observable, map } from 'rxjs';
import { GknBadge } from 'src/app/edu-training/certifications-and-badges/certifications-and-badges.interface';
import { Product, ProductService } from '../../../@shared/services/product';

@Component({
    selector: 'app-my-awards',
    templateUrl: './my-awards.component.html',
    styleUrls: ['./my-awards.component.css'],
})
export class MyAwardsComponent implements TrackByItem<GknBadge>, OnInit {
    sectionLabel = EduTrainingBadgesFilterLabel.MY_AWARDS;
    @Input() badges: GknBadge[];
    @Input() showTitle = true;
    favoriteId = FavoriteId.CERTIFICATIONS_MY_AWARDS;
    externalUrl = 'https://www.credly.com/users/sign_in';
    selectedProduct$: Observable<Product>;
    courseLink$: Observable<string>;

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        this.selectedProduct$ = this.productService.selectedProduct$;

        this.courseLink$ = this.productService.selectedProduct$.pipe(
            map((product) => {
                switch (product.uid) {
                    case ProductUid.GENESYS_ENGAGE_ON_PREM:
                    // fall-through
                    case ProductUid.GENESYS_MULTICLOUD:
                        return 'https://beyond.genesys.com/explore/certification/genesys-engage-exams';
                    case ProductUid.POINTILLIST:
                        return 'https://beyond.genesys.com/explore/subscription/pointillist-learning-subscription';
                    case ProductUid.PURECONNECT:
                        return 'https://beyond.genesys.com/explore/certification/genesys-pureconnect-exams';
                    default:
                        return 'https://beyond.genesys.com/explore/certification/cloud';
                }
            }),
        );
    }

    trackByItem(_index: number, item: GknBadge): NonNullable<number | string> {
        return item.title;
    }
}
