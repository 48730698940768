import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';
import { environment } from '@environments/environment';
import { RouteService } from '@services';
import { combineLatestWith, firstValueFrom, map, Observable, of } from 'rxjs';
import { GknRoutePath } from '../../app-routing.enum';
import { SupportOutageAlert } from './support-outage-banner.interface';
import { SupportOutageBannerService } from './support-outage-banner.service';

@Component({
    selector: 'app-support-outage-banner',
    templateUrl: './support-outage-banner.component.html',
    styleUrls: ['./support-outage-banner.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SupportOutageBannerComponent implements OnInit {
    statusPageCta = 'Learn more';
    statusPageUrl = 'https://status.mypurecloud.com';
    status: SupportOutageAlert;
    supportOutageAlert$: Observable<boolean>;
    timeZone: string;
    mouseover: boolean;

    constructor(
        private bo: BreakpointObserver,
        private dialog: MatDialog,
        private routeService: RouteService,
        private supportOutageService: SupportOutageBannerService,
    ) {}

    ngOnInit(): void {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.supportOutageAlert$ = this.routeService.currentRoute$.pipe(
            map((supportPage) => supportPage == GknRoutePath.SUPPORT),
            combineLatestWith(
                this.supportOutageService.supportOutageAlert$.pipe(
                    map((summary) => {
                        if (environment.name === 'dev') {
                            return true;
                        } else if (summary.components) {
                            // get the name corresponding to the user's time zone
                            const componentName = this.lookupComponentName(this.timeZone);
                            this.timeZone = componentName;
                            // try to find components starting with the component name who's status is currently something like 'outage'
                            const hasOutage = summary.components.some(
                                (x) =>
                                    x.name.startsWith(componentName) && x.status.toLowerCase().indexOf('outage') >= 0,
                            );
                            return hasOutage;
                        } else {
                            return false;
                        }
                    }),
                ),
            ),
            map(([isSupportPage, isSupportOuatageAlert]) => isSupportPage && isSupportOuatageAlert),
        );
    }

    /**
     * Region name selection according to timezone.
     */
    lookupComponentName(timeZone: string): string {
        const timeZoneStart = timeZone.substring(0, timeZone.indexOf('/'));
        switch (timeZoneStart) {
            case 'America':
            // fall-through
            case 'Atlantic':
                return 'Americas';
            case 'Africa':
            // fall-through
            case 'Europe':
                return 'EMEA';
            case 'Asia':
            // fall-through
            case 'Australia':
            // fall-through
            case 'Indian':
            // fall-through
            case 'Pacific':
                return 'Asia Pacific';
            default:
                return '';
        }
    }

    handleOpenDialog(): void {
        firstValueFrom(
            this.bo.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: 'Genesys Cloud Conversation Failures: ' + this.timeZone,
                    subtitle: 'Your region has been detected based on your computer settings.',
                    subtitlePosition: 'bottom',
                    src: this.statusPageUrl,
                    dataAnalytics: ['support', 'outage', 'dialog'],
                },
            });
        });
    }

    closeAlert(): void {
        this.supportOutageAlert$ = of(false);
    }
}
