import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import Player from '@vimeo/player';
import { map, Observable } from 'rxjs';

/**
 * Angular component wrapper for https://www.npmjs.com/package/@vimeo/player.
 * Our app defines a div with attribute "data-vimeo-url", which is then
 * automatically scanned by the library and populated with a Vimeo iframe.
 *
 * @example
 * <app-vimeo-player videoId="XXXXX"></app-vimeo-player>
 */
@Component({
    selector: 'app-vimeo-player',
    templateUrl: './vimeo-player.component.html',
    styleUrls: ['./vimeo-player.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit {
    /**
     * vimeo video id, e.g. "12345" in https://vimeo.com/12345
     */
    @Input() videoId!: string;

    /**
     * optional property inidicating this player component
     * will be shown in a dialog, which impacts the DOM ID for this player
     */
    @Input() dialog = false;

    /**
     * optional width property. defaults to 330px, a good option for
     * card layouts with 3 columns
     */
    @Input() width = '330';

    /**
     * unique id for the vimeo player. showing a secondary view of
     * the video (such as on a home page and onClick in a dialog from
     * the home page) requires a separate unique ID for each
     * app-vimeo-player instance
     */
    id!: string;

    /** player.vimeo.com url */
    url!: string;

    /** whether the app is running on a mobile device */
    isMobile$: Observable<boolean>;

    constructor(private bo: BreakpointObserver) {}

    ngOnInit(): void {
        this.id = (this.dialog ? 'vimeo-player-dialog-' : 'vimeo-player-inline-') + this.videoId;
        this.url = 'https://player.vimeo.com/video/' + this.videoId;
        this.isMobile$ = this.bo.observe('(max-width: 1020px)').pipe(map((state) => state.matches));
    }

    ngAfterViewInit(): void {
        const player = new Player(this.id); // This instantiation is what causes the DOM to render the player with our div

        player.on('play', function () {
            console.debug('vimeo player::played', this.videoId);
        });

        player.on('pause', function () {
            console.debug('vimeo player::paused', this.videoId);
        });

        player.on('ended', function () {
            console.debug('vimeo player::ended', this.videoId);
        });
    }
}
