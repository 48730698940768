<app-tile-layout *desktop maxColumns="2" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
    <li class="media-container">
        <ng-container *ngTemplateOutlet="videoEmbed"></ng-container>
    </li>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="1" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="videoEmbed"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
</app-tile-layout>

<ng-template #title>
    <h2 class="h2-alt margin-bottom-small">{{ featuredContent?.title }}</h2>
</ng-template>

<ng-template #description>
    <app-featured-content-wysiwyg [data]="featuredContent.description"></app-featured-content-wysiwyg>
</ng-template>

<ng-template #cta>
    <button class="btn btn-secondary margin-top-small" (click)="handleOpenDialog(featuredContent)">
        {{ featuredContent?.urlLabel }}
    </button>
</ng-template>

<ng-template #videoEmbed>
    <app-video-container
        [title]="featuredContent.title"
        [embedUrl]="featuredContent.url"
        [containerCss]="['tile-border-radius', 'mat-elevation-z6']"
        iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'">
    </app-video-container>
</ng-template>
