import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '@directives';
import { PipesModule } from '@pipes';
import { TileLayoutModule } from '../../tile-layout/tile-layout.module';
import { FeaturedContentWysiwygModule } from '../featured-content-wysiwyg/featured-content-wysiwyg.module';
import { FeaturedContentThumbnailComponent } from './featured-content-thumbnail.component';

@NgModule({
    declarations: [FeaturedContentThumbnailComponent],
    imports: [
        CommonModule,
        DirectivesModule,
        FeaturedContentWysiwygModule,
        MatDialogModule,
        PipesModule,
        TileLayoutModule,
    ],
    exports: [FeaturedContentThumbnailComponent],
})
export class FeaturedContentThumbnailModule {}
