import { Injectable } from '@angular/core';
import {
    CommunitySectionLabel,
    EduTrainingSectionLabel,
    FavoriteId,
    GetInvolvedSectionLabel,
    HomeSectionLabel,
    ProductUid,
    RecommendationSectionLabel,
    ResourcesSectionLabel,
    SupportSectionLabel,
} from '@enums';
import { ProductService, UserService } from '@services';
import { combineLatestWith, map, of } from 'rxjs';
import { GknRoutePath } from '../app-routing.enum';
import { SubmenuLink } from './submenu-data';

@Injectable({
    providedIn: 'root',
})
export class AppHeaderService {
    public submenuLink: SubmenuLink[];

    constructor(private productService: ProductService, private userService: UserService) {
        this.submenuLink = [
            {
                url: GknRoutePath.HOME,
                sections: [
                    {
                        label$: of(HomeSectionLabel.MY_FAVORITES),
                        location: FavoriteId.MY_FAVORITES,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            map((isAuthenticated) => isAuthenticated === true),
                        ),
                    },
                    {
                        label$: of(HomeSectionLabel.JUST_FOR_ME),
                        location: FavoriteId.JUST_FOR_ME,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            map((isAuthenticated) => isAuthenticated === false),
                        ),
                    },
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + HomeSectionLabel.FUNDAMENTALS),
                        ),
                        location: FavoriteId.PRODUCT_FUNDAMENTALS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                    {
                        label$: of(HomeSectionLabel.NEWS_FROM_GENESYS),
                        location: FavoriteId.NEWS_FROM_GENESYS_ALL,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.COMMUNITY,
                sections: [
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + CommunitySectionLabel.LATEST_DISCUSSIONS),
                        ),
                        location: FavoriteId.LATEST_DISCUSSIONS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(CommunitySectionLabel.QA_SHOW),
                        location: FavoriteId.COMMUNITY_QA_SHOW,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(CommunitySectionLabel.RELATED_COMMUNITIES),
                        location: FavoriteId.RELATED_COMMUNITIES,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.GET_INVOLVED,
                sections: [
                    {
                        label$: of(GetInvolvedSectionLabel.CONNECT_WITH_GENESYS),
                        location: FavoriteId.CONNECT_WITH_GENESYS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(GetInvolvedSectionLabel.MARKETING_EVENTS),
                        location: FavoriteId.MARKETING_EVENTS,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.EDUCATION_AND_TRAINING,
                sections: [
                    {
                        label$: of(EduTrainingSectionLabel.BEYOND_TRAINING),
                        location: FavoriteId.BEYOND,
                        hidden$: of(false),
                    },
                    {
                        label$: of(EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES),
                        location: FavoriteId.CERTIFICATIONS_MY_AWARDS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(EduTrainingSectionLabel.EDU_EVENTS_AND_WEBINARS),
                        location: FavoriteId.EDU_EVENTS_AND_WEBINARS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                ],
            },
            {
                url: GknRoutePath.RESOURCES,
                sections: [
                    {
                        label$: of(ResourcesSectionLabel.GET_STARTED),
                        location: FavoriteId.GET_STARTED,
                        hidden$: of(false),
                    },
                    {
                        label$: of(ResourcesSectionLabel.CX_EVOLUTION),
                        location: FavoriteId.CX_EVOLUTION,
                        hidden$: of(false),
                    },
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + ResourcesSectionLabel.PRODUCT_HELP),
                        ),
                        location: FavoriteId.PRODUCT_HELP_ARTICLES,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.DEV_CENTER),
                        location: FavoriteId.DEVELOPER_CENTER_TOOLS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map(
                                (product) =>
                                    product.uid === ProductUid.GENESYS_ENGAGE_ON_PREM ||
                                    product.uid === ProductUid.PURECONNECT ||
                                    product.uid === ProductUid.POINTILLIST,
                            ),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.DEV_PORTAL_PURECONNECT),
                        location: FavoriteId.DEVELOPER_PORTAL_PURECONNECT,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.PURECONNECT),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.EXPERT_APPS),
                        location: FavoriteId.EXPERT_APPS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.APP_FOUNDRY),
                        location: FavoriteId.APP_FOUNDRY,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.CUSTOMER_SUCCESS),
                        location: FavoriteId.CUSTOMER_SUCCESS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                ],
            },
            {
                url: GknRoutePath.SUPPORT,
                sections: [
                    {
                        label$: of(SupportSectionLabel.MY_CASES),
                        location: FavoriteId.SUPPORT_CASES,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            combineLatestWith(this.userService.isEmployee$, this.userService.sfdcUserDetail$),
                            map(
                                ([isAuthenticated, isEmployee, userDetail]) =>
                                    !isAuthenticated || isEmployee || !userDetail?.Permissions?.MySupport,
                            ),
                        ),
                    },
                    {
                        label$: of(RecommendationSectionLabel.RECOMMENDATIONS),
                        location: FavoriteId.RECOMMENDATIONS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(SupportSectionLabel.PRODUCT_SUPPORT_CENTER),
                        location: FavoriteId.PRODUCT_SUPPORT_CENTER,
                        hidden$: of(false),
                    },
                    {
                        label$: of(SupportSectionLabel.MY_CONTACTS),
                        location: FavoriteId.MY_CONTACTS,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            combineLatestWith(this.userService.isEmployee$),
                            map(([isAuthenticated, isEmployee]) => !isAuthenticated || isEmployee),
                        ),
                    },
                ],
            },
        ];
    }
}
