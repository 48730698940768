import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Angular Material dialog wrapper for our properietary VimeoPlayerComponent
 *
 * @example
 * handleOpenDialog(videoId: string): void {
 *   this.dialog.open(VimeoPlayerDialogComponent, {
 *     data: {
 *       title: 'my video',
 *       videoId: videoId
 *     },
 *   });
 * }
 */
@Component({
    selector: 'app-vimeo-player-dialog',
    templateUrl: './vimeo-player-dialog.component.html',
    styleUrls: ['./vimeo-player-dialog.component.css'],
})
export class VimeoPlayerDialogComponent {
    colors = {
        close: 'primary',
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
