import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { SupportOutageBannerComponent } from './support-outage-banner.component';
import { SupportOutageBannerService } from './support-outage-banner.service';

@NgModule({
    declarations: [SupportOutageBannerComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule, MatButtonModule, MatDialogModule, MatIconModule],
    providers: [SupportOutageBannerService],
    exports: [SupportOutageBannerComponent],
})
export class SupportOutageBannerModule {}
