import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { PipesModule } from '@pipes';
import { MarketingWpService } from '@services';
import { NewsComponent } from './news.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [NewsComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        MatTabsModule,
        MatDialogModule,
        NoopAnimationsModule,
    ],
    providers: [MarketingWpService],
    exports: [NewsComponent],
})
export class NewsModule {}
