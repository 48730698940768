import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ShortTracksComponent } from './short-tracks.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [ShortTracksComponent],
    imports: [CommonModule, ComponentsModule, MatDialogModule],
    exports: [ShortTracksComponent],
})
export class ShortTracksModule {}
