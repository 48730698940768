import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '@directives';
import { TileLayoutModule } from '../../tile-layout/tile-layout.module';
import { VideoContainerModule } from '../../video-container/video-container.module';
import { FeaturedContentWysiwygModule } from '../featured-content-wysiwyg/featured-content-wysiwyg.module';
import { FeaturedContentVideoEmbedComponent } from './featured-content-video-embed.component';

@NgModule({
    declarations: [FeaturedContentVideoEmbedComponent],
    imports: [
        CommonModule,
        DirectivesModule,
        FeaturedContentWysiwygModule,
        MatDialogModule,
        TileLayoutModule,
        VideoContainerModule,
    ],
    exports: [FeaturedContentVideoEmbedComponent],
})
export class FeaturedContentVideoEmbedModule {}
