import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_SCROLL_STRATEGY, MatSelectModule } from '@angular/material/select';
import { SelectComponent } from './select.component';

@NgModule({
    declarations: [SelectComponent],
    imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
    exports: [SelectComponent],

    providers: [
        {
            // Scrolling is disabled while this select component is opened
            // to avoid z-index issues with the rest of the site,
            // most notably the header.
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.block(),
            deps: [Overlay],
        },
    ],
})
export class SelectModule {}
