import { Component, Input, OnInit } from '@angular/core';
import { FavoriteId, SupportSectionLabel } from '@enums';
import { KeplerSfdcUser, UserService } from '@services';

import { Observable, combineLatest, map } from 'rxjs';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
    sectionLabel = SupportSectionLabel.MY_CONTACTS;
    favoriteId = FavoriteId.MY_CONTACTS;
    sectionLocation = FavoriteId.MY_CONTACTS;
    contacts$: Observable<KeplerSfdcUser[]>;

    defaultContact: KeplerSfdcUser = {
        Name: 'gCSM Team',
        Email: 'cxspecialist@genesys.com',
    };

    @Input() showIcons = true;
    @Input() showTitle = true;

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        this.contacts$ = combineLatest([this.userService.csmUser$, this.userService.advisorUser$]).pipe(
            map(([csmUser, advisorUser]) => {
                if (!csmUser && !advisorUser) {
                    return [this.defaultContact];
                } else {
                    const contacts: KeplerSfdcUser[] = [];
                    if (csmUser) contacts.push(csmUser);
                    if (advisorUser) contacts.push(advisorUser);
                    return contacts;
                }
            }),
        );
    }
}
