import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { YouTubeInterface } from './youtube.interface';

@Injectable({
    providedIn: 'root',
})
export class YoutubeService {
    private youtubeApiUrl = `${environment.youtube.url}`;
    private youtubeApiKey = `${environment.youtube.key}`;

    constructor(private http: HttpClient) {}

    /**
     * Return Observable array of videos by playlist Id
     * @returns Observable array of youtube videos of playlist
     */
    getYouTubePlayList(youTubePlaylistId: string): Observable<YouTubeInterface[]> {
        const url = new URL(this.youtubeApiUrl + 'playlistItems');
        url.searchParams.set('playlistId', youTubePlaylistId);
        url.searchParams.set('key', this.youtubeApiKey);
        url.searchParams.set('part', 'snippet,contentDetails');
        url.searchParams.set('maxResults', '15');
        return this.http.get<YouTubeInterface[]>(url.toString());
    }

    /**
     * Return Observable array of videos from YouTube, based on ids passed to function.
     * @returns Observable array of YouTube videos
     *
     * @param videoIds - array of ids
     */
    getVideoByIds(videoIds: string[]): Observable<YouTubeInterface[]> {
        const url = new URL(this.youtubeApiUrl + 'playlistItems');
        url.searchParams.set('part', 'snippet, contentDetails');
        url.searchParams.set('id', videoIds.join(','));
        url.searchParams.set('key', this.youtubeApiKey);
        return this.http.get<YouTubeInterface[]>(url.toString());
    }
}
