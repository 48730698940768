<p class="p-body-large" [class.mobile]="isMobile">
    <ng-container *ngIf="!hasCompletedStarterKit">
        Welcome to your {{ tab.name }} journey. These resources can be clicked through now and saved for your later
        convenience.
    </ng-container>
    <ng-container *ngIf="hasCompletedStarterKit">
        Congratulations on completing your {{ tab.name }} Starter Kit! Be sure to bookmark
        <a class="end-link" target="_blank" href="https://know.genesys.com/resources?product=genesys-cloud-cx"
            >this page</a
        >
        as well as these other great resources:
        <a class="end-link" target="_blank" href="https://community.genesys.com/home">Genesys Community</a>,
        <a class="end-link" target="_blank" href="https://help.mypurecloud.com/">Resource Center</a> and
        <a class="end-link" target="_blank" href="https://developer.genesys.cloud/">Developer Center</a>.
    </ng-container>
    <ng-container *ngIf="!isAuthenticated">
        <button class="button-link link-n sign-in-button" (click)="login()">Sign In</button> to begin.
        <span class="tooltip-container">
            <app-info-tooltip [isMobile]="isMobile">
                Roles that we recommend complete these sections include: System Admins, Project Managers, Technical
                Leads and Contact Center Managers.
            </app-info-tooltip>
        </span>
    </ng-container>
    <b *ngIf="isMobile">{{ tab.mobileMessage }}</b>
</p>
<app-starter-kit-steps
    [steps]="tab.steps"
    [isMobile]="isMobile"
    [selectedIndex]="selectedStepIndex"
    (selectStep)="selectedStepIndex = $event"
    [access]="isAuthenticated"
    [order]="tab.order"
    [updateToLatestStep$]="updateToLatestStep$?.asObservable()"
    (isDoneWithStarterKit)="updateParagraph($event)"></app-starter-kit-steps>
<div class="step-content-container" *ngIf="isAuthenticated && tab.steps[selectedStepIndex] != undefined">
    <app-starter-kit-step-content
        [step]="tab.steps[selectedStepIndex]"
        [tab]="tab"
        [selectedIndex]="selectedStepIndex"
        (navigate)="selectedStepIndex = selectedStepIndex + $event"
        (completed)="completed(selectedStepIndex)"
        [isMobile]="isMobile"></app-starter-kit-step-content>
</div>
