<app-section-header
    [title]="sectionLabel"
    [section]="favoriteId"
    [favoriteId]="favoriteId"
    [externalUrl]="externalUrl"
    [anchors]="anchors"></app-section-header>

<mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" appFilterLocation>
    <mat-tab *ngIf="displayFilters.welcomeVideo" [label]="getStartedFilterLabel.MY_PERSONALIZED_VIDEO">
        <ng-template matTabContent>
            <app-welcome-video></app-welcome-video>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.starterKit" [label]="getStartedFilterLabel.STARTER_KIT">
        <app-starter-kit></app-starter-kit>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.onboardingWebinars" [label]="getStartedFilterLabel.NEW_CUSTOMER_ONBOARDING">
        <app-onboarding-webinars></app-onboarding-webinars>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.shortTracks" [label]="getStartedFilterLabel.SHORT_TRACKS">
        <app-short-tracks></app-short-tracks>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.usefulLinks" [label]="getStartedFilterLabel.USEFUL_LINKS">
        <app-useful-links></app-useful-links>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.tipsAndTools" [label]="getStartedFilterLabel.TIPS_AND_TOOLS">
        <app-tips-and-tools></app-tips-and-tools>
    </mat-tab>
</mat-tab-group>
