import { FavoriteId, ResourcesSectionLabel } from '@enums';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { expertAppsData } from './expert-apps-data';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-expert-apps',
    templateUrl: './expert-apps.component.html',
    styleUrls: ['./expert-apps.component.css'],
})
export class ExpertAppsComponent implements OnInit, OnDestroy {
    favoriteId = FavoriteId.EXPERT_APPS;
    sectionLabel = ResourcesSectionLabel.EXPERT_APPS;
    sectionSubTitle = '(popular)';
    tiles = expertAppsData;
    sectionLocation = FavoriteId.EXPERT_APPS;

    isMobileViewport = false;
    subs: Subscription[] = [];

    constructor(private breakpointObserver: BreakpointObserver, private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((state: BreakpointState) => {
                this.isMobileViewport = state.matches;
                this.changeDetector.markForCheck();
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
