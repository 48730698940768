<div class="beyond-icon-container">
    <app-tile-vertical
        [title]="beyondTrainingTile?.title | stripHtml"
        [titleLink]="true"
        [titleMinHeight]="false"
        [details]="subtitle$ | async"
        [icon]="beyondTrainingTile?.subtitleIcon"
        [description]="beyondTrainingTile?.description | stripHtml"
        [url]="beyondTrainingTile?.url"
        [videoUrl]="true"
        [bigImage]="bigImage"
        [vertical]="vertical"
        [isCustomTitleLink]="true"
        [isCustomFooterLink]="true"
        [border]="false"
        [dataAnalytics]="dataAnalytics">
        <div customTitleLink>
            <button
                id="titleCta"
                class="button-link cursor primary-link"
                (click)="handleOpenDialog(beyondTrainingTile)"
                [attr.data-analytics]="dataAnalyticsModal">
                <h2 class="ellipsis-2 sub-title-3 text-brand-navy (bigImage ? margin-top-small : margin-top-smaller)">
                    {{ beyondTrainingTile?.title | stripHtml }}
                </h2>
            </button>
        </div>
        <div customFooterLink>
            <div class="external-link-div">
                <button
                    id="buttonCta"
                    class="button-link cursor link-n"
                    (click)="handleOpenDialog(beyondTrainingTile)"
                    [attr.data-analytics]="dataAnalyticsModal">
                    {{ beyondTrainingTile?.cta }}
                </button>
                <div class="external-icons">
                    <app-share [link]="beyondTrainingTile?.url"></app-share>
                    <app-external-url [url]="beyondTrainingTile?.url"></app-external-url>
                </div>
            </div>
        </div>
    </app-tile-vertical>
</div>
