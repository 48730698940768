import { Component, Input, OnInit } from '@angular/core';

import { ProductService } from '@services';
import { firstValueFrom, map, take } from 'rxjs';
import { TileSmall } from './tile-small.interface';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '../dialogs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ProductUid } from '@enums';

@Component({
    selector: 'app-tile-small',
    templateUrl: './tile-small.component.html',
    styleUrls: ['./tile-small.component.css'],
})
export class TileSmallComponent implements OnInit {
    @Input() altText: string;
    @Input() tileSmallData: TileSmall;
    @Input() dark: true;

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private productService: ProductService,
    ) {}

    ngOnInit(): void {
        this.productService.selectedProduct$.pipe(take(1)).subscribe((product) => {
            if (product.uid === ProductUid.PURECONNECT && this.tileSmallData?.label === 'Best Practices') {
                this.tileSmallData.LoadsInFrame = false;
            }
        });
    }

    /**
     * Open MUI Modal Dialog with TileSmall data
     * @param link - TileSmall
     */
    handleOpenDialog(tileSmallData: TileSmall) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: tileSmallData?.label,
                    src: tileSmallData?.url,
                    dataAnalytics: 'tile-small',
                },
            });
        });
    }
}
