import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { BlogComponent } from './blog/blog.component';
import { DeveloperCenterComponent } from './developer-center.component';
import { PopularTopicsComponent } from './popular-topics/popular-topics.component';
import { ToolsModule } from './tools/tools.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [DeveloperCenterComponent, BlogComponent, PopularTopicsComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule, MatTabsModule, MatDialogModule, ToolsModule],
    exports: [DeveloperCenterComponent],
})
export class DeveloperCenterModule {}
