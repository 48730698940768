import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { VimeoPlayerModule } from '../../vimeo-player/vimeo-player.module';
import { VimeoPlayerDialogComponent } from './vimeo-player-dialog.component';

@NgModule({
    declarations: [VimeoPlayerDialogComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule, MatIconModule, VimeoPlayerModule],
    exports: [VimeoPlayerDialogComponent],
})
export class VimeoPlayerDialogModule {}
