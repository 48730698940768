<app-tile-layout *desktop maxColumns="2" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
    <li class="media-container">
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
    </li>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="1" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
</app-tile-layout>

<ng-template #title>
    <h2 class="h2-alt margin-bottom-small">{{ featuredContent?.title }}</h2>
</ng-template>

<ng-template #description>
    <app-featured-content-wysiwyg [data]="featuredContent.description"></app-featured-content-wysiwyg>
</ng-template>

<ng-template #cta>
    <a
        *ngIf="featuredContent.type == 'newTab'"
        class="btn btn-secondary margin-top-small"
        [href]="featuredContent?.url"
        target="_blank">
        {{ featuredContent?.urlLabel }}
    </a>
    <button
        *ngIf="featuredContent.type == 'modal'"
        class="btn btn-secondary margin-top-small"
        (click)="handleOpenDialog(featuredContent)">
        {{ featuredContent?.urlLabel }}
    </button>
</ng-template>

<ng-template #thumbnail>
    <img
        *desktop
        class="full-width tile-border-radius mat-elevation-z6"
        width="auto"
        height="auto"
        [alt]="featuredContent.title"
        [src]="featuredContent?.thumbnail?.desktop" />
    <img
        *mobile
        class="full-width tile-border-radius mat-elevation-z6"
        width="auto"
        height="auto"
        [alt]="featuredContent?.title"
        [src]="featuredContent?.thumbnail?.mobile" />
</ng-template>
