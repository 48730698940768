<ng-container *ngIf="isAuthenticated$ | async; else myFavorites">
    <app-just-for-me></app-just-for-me>
</ng-container>
<ng-template #myFavorites>
    <app-section-header [title]="myFavoritesLabel" [section]="myFavoritesLocation"></app-section-header>
    <app-my-favorites></app-my-favorites>
</ng-template>
<hr />

<ng-container *ngIf="showFundamentals$ | async">
    <app-fundamentals></app-fundamentals>
    <hr />
</ng-container>

<ng-container *ngIf="featuredContent$ | async as featuredContent">
    <app-featured-content [featuredContent]="featuredContent"></app-featured-content>
    <hr />
</ng-container>
<app-news></app-news>
