import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';

export interface IframeDialogData {
    title: string;
    subtitle?: string;
    subtitlePosition?: '' | 'right' | 'bottom';
    src: string;
    dataAnalytics?: string[];
}

@Component({
    selector: 'app-iframe-dialog-component',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.css'],
})
export class IframeDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    @ViewChild('dialogContent') dialogContent: ElementRef;

    isCopied = false;

    /**
     * String identifiers for analytics services to track this tile, joined with hyphen in HTML
     */
    dataAnalytics: string[];
    dataAnalyticsCopy: string;
    dataAnalyticsClose: string;
    dataAnalyticsOpenInNew: string;

    colors = {
        copy: 'primary',
        close: 'primary',
        open: 'primary',
    };

    constructor(
        private clipboard: Clipboard,
        public readonly dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: IframeDialogData,
    ) {
        console.log('data', data);
    }

    ngOnInit(): void {
        this.dataAnalytics = this.data?.dataAnalytics;
        this.dataAnalyticsCopy = ['copy', 'modal', this.dataAnalytics].join('-');
        this.dataAnalyticsClose = ['close', 'modal', this.dataAnalytics].join('-');
        this.dataAnalyticsOpenInNew = ['open-in-new-window', 'modal', this.dataAnalytics].join('-');
    }

    /**
     * Copy the iframe src to the clipboard
     * @param e Optional event to prevent default behavior
     */
    handleCopy(e?: Event): void {
        e?.preventDefault();

        if (this.data.src.startsWith('https://help.mypurecloud.com') && this.data.src.includes('theme=simplified')) {
            const resourceCenterUrl = new URL(this.data.src);
            resourceCenterUrl.searchParams.delete('theme');
            this.clipboard.copy(resourceCenterUrl.toString());
        } else {
            this.clipboard.copy(this.data.src);
        }

        this.isCopied = true;

        this.subs.push(
            timer(2000).subscribe(() => {
                this.isCopied = false;
            }),
        );
    }

    /**
     * Unsubscribe from all subscriptions on destroy
     * @returns void
     */
    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
