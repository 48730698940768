import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';
import { ProductUid } from '@enums';
import { GknFundamentalCardLink } from '@services';
import { catchError, EMPTY, firstValueFrom, map, Observable, tap } from 'rxjs';
import { FundamentalsCardType } from '../fundamentals-card/fundamentals-card.enum';
import { ReleaseNotesService } from './release-notes.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.css'],
})
export class ReleaseNotesComponent implements OnInit {
    releaseNotes$: Observable<GknFundamentalCardLink[]>;
    isLoading = true;
    productEnum = ProductUid;
    colors: string[] = [];

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private releaseNotesService: ReleaseNotesService,
    ) {}

    ngOnInit(): void {
        this.releaseNotes$ = this.releaseNotesService.releaseNotes$.pipe(
            tap(() => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }),
            catchError((err) => {
                console.error(err);
                return EMPTY;
            }),
        );
    }

    /**
     * Open MUI Modal Dialog with GknFundamentalCardLink data
     * @param link - GknFundamentalCardLink
     */
    handleOpenDialog(link: GknFundamentalCardLink) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '60%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: FundamentalsCardType.RELEASE_NOTES,
                    subtitle: link.text,
                    src: link.url + '?theme=simplified',
                    dataAnalytics: 'release-notes',
                },
            });
        });
    }
}
