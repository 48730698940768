<ng-container *ngIf="tileSmallData?.LoadsInFrame; else nonModalTile">
    <div [ngClass]="{ dark: dark, 'bg-brand-navy': dark }" class="tile-small mat-elevation-z6">
        <a class="neutral-link" rel="nofollow" (click)="handleOpenDialog(tileSmallData)">
            <ng-container *ngTemplateOutlet="tileContent"> </ng-container>
        </a>
    </div>
</ng-container>

<ng-template #nonModalTile>
    <div [ngClass]="{ dark: dark, 'bg-brand-navy': dark }" class="tile-small mat-elevation-z6">
        <a class="neutral-link" [href]="tileSmallData?.url" target="_blank" rel="nofollow">
            <ng-container *ngTemplateOutlet="tileContent"></ng-container>
        </a>
    </div>
</ng-template>

<ng-template #tileContent>
    <img
        [src]="tileSmallData?.iconUrl"
        [attr.width]="tileSmallData?.width"
        [attr.height]="tileSmallData?.height"
        [alt]="altText" />
    <h2
        class="h3 sub-title-3-alt margin-top-small"
        [ngClass]="{
            'text-white': dark,
            'text-brand-navy': !dark
        }">
        {{ tileSmallData?.label }}
    </h2>
</ng-template>
