import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FavoriteId, ProductUid, ResourcesSectionLabel } from '@enums';
import { Product, ProductService } from '@services';
import { Subscription, map } from 'rxjs';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Tile } from '@components';
import { AppFoundryEntity } from './app-foundry-entity.interface';
import { AppFoundryService } from './app-foundry.service';

@Component({
    selector: 'app-app-foundry',
    templateUrl: './app-foundry.component.html',
    styleUrls: ['./app-foundry.component.css'],
})
export class AppFoundryComponent implements OnInit, OnDestroy {
    sectionLabel = `${ResourcesSectionLabel.APP_FOUNDRY}`;
    sectionSubTitle = '(essentials)';
    favoriteId = FavoriteId.APP_FOUNDRY;
    externalUrl: string;
    tiles: Tile[];
    private subs: Subscription[] = [];
    sectionLocation = FavoriteId.APP_FOUNDRY;
    isMobileViewport = false;

    private mapEntity = (entity: AppFoundryEntity): Tile => {
        return {
            imageUrl: entity.appLogo,
            title: entity.name['en-us'],
            description: entity.tagline['en-us'],
            url: this.buildLearnMoreUrl(entity),
        };
    };

    constructor(
        private appFoundryService: AppFoundryService,
        private changeDetector: ChangeDetectorRef,
        private productService: ProductService,
        private breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.productService.selectedProduct$.subscribe((product: Product) => {
                const platform = this.getAppFoundryPlatform(product.uid);
                this.externalUrl = this.buildExternalUrl(platform);

                this.subs.push(
                    this.appFoundryService
                        .getEssentials(platform)
                        .pipe(map((entities: AppFoundryEntity[]) => entities.map(this.mapEntity)))
                        .subscribe((tiles: Tile[]) => {
                            this.tiles = tiles;
                            this.changeDetector.markForCheck();
                        }),
                );
            }),
        );
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((state: BreakpointState) => {
                this.isMobileViewport = state.matches;
                this.changeDetector.markForCheck();
            }),
        );
    }

    private buildExternalUrl(platform: string): string {
        return 'https://appfoundry.genesys.com/filter/' + platform.toLowerCase();
    }

    private buildLearnMoreUrl(entity: AppFoundryEntity): string {
        return 'https://appfoundry.genesys.com/filter/' + entity.platform.name.toLowerCase() + '/listing/' + entity.id;
    }

    private getAppFoundryPlatform(productUid: ProductUid): string {
        switch (productUid) {
            case ProductUid.GENESYS_MULTICLOUD:
            // fall-through
            case ProductUid.GENESYS_ENGAGE_ON_PREM:
                return 'GenesysEngage';
            case ProductUid.PURECONNECT:
                return 'PureConnect';
            case ProductUid.GENESYS_CLOUD_CX:
            // fall-through
            default:
                return 'GenesysCloud';
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
