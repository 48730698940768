<app-fundamentals-card title="Status" icon="/assets/icons/status.svg" [isLoading]="isLoading">
    <ul>
        <li *ngFor="let link of incidents$ | async; let i = index; trackBy: trackByItem">
            <button
                mat-button
                [ngClass]="{
                    btn: i == 0,
                    'mat-button-link': i != 0
                }"
                [color]="colors[i] ? colors[i] : (colors[i] = i == 0 ? 'primary' : 'accent')"
                (mouseover)="colors[i] = i == 0 ? 'accent' : 'primary'"
                (mouseout)="colors[i] = i == 0 ? 'primary' : 'accent'"
                (click)="handleOpenDialog(link)"
                [attr.data-analytics]="link.dataAnalytics?.join('-')">
                {{ link.text }}
            </button>
        </li>
    </ul>
</app-fundamentals-card>
