<header mat-dialog-title>
    <div class="title">
        <h2 class="h3">{{ data.title }}</h2>
        <!-- <mat-divider [vertical]="true"></mat-divider> -->
        <h3 class="sub-title-4 text-secondary-gray-1" *ngIf="data.subtitle">{{ data.subtitle }}</h3>
    </div>
    <div class="actions">
        <button
            mat-icon-button
            mat-dialog-close
            [color]="colors.close"
            (mouseover)="colors.close = 'accent'"
            (mouseout)="colors.close = 'primary'"
            aria-label="Close"
            [attr.data-analytics]="data.dataAnalytics.join('-')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</header>
<mat-dialog-content>
    <mat-divider></mat-divider>
    <app-video-container
        [embedUrl]="data.url"
        iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        containerCss="mat-elevation-z6"
        [dataAnalytics]="data.dataAnalytics"></app-video-container>
</mat-dialog-content>
