import { Component, OnInit, ViewChild } from '@angular/core';
import { FavoriteId, HomeSectionLabel, ProductDisplayName, ProductUid, UserRoleUid } from '@enums';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Observable } from 'rxjs';

import { TrackByItem } from '@components';
import { Product, ProductService, UserService } from '@services';
import { FundamentalsCardComponent } from './fundamentals-card/fundamentals-card.component';

@Component({
    selector: 'app-fundamentals',
    templateUrl: './fundamentals.component.html',
    styleUrls: ['./fundamentals.component.css'],
})
export class FundamentalsComponent implements OnInit, TrackByItem<FundamentalsCardComponent> {
    sectionLabel = HomeSectionLabel.FUNDAMENTALS;
    sectionLocation = FavoriteId.PRODUCT_FUNDAMENTALS;
    favoriteId = FavoriteId.PRODUCT_FUNDAMENTALS;
    sectionHyperlinkLabel = 'Log in to ' + ProductDisplayName[ProductUid.GENESYS_CLOUD_CX];

    isAuthenticated$: Observable<boolean>;
    selectedProduct$: Observable<Product>;
    productEnum = ProductUid;
    userRoleEnum = UserRoleUid;

    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 400,
        interval: {
            timing: 10000, // Standard 10 sec between slides
            initialDelay: 1000,
        },
        point: {
            visible: true,
        },
        load: 2,
        loop: false,
        touch: true, // touch is not currently in active for vertical carousel, will enable it in future build
        vertical: {
            enabled: false,
            height: 400,
        },
    };

    constructor(private productService: ProductService, private userService: UserService) {}

    ngOnInit(): void {
        this.isAuthenticated$ = this.userService.isAuthenticated$;
        this.selectedProduct$ = this.productService.selectedProduct$;
    }

    trackByItem(index: number, _data: any): NonNullable<number | string> {
        return index;
    }
}
