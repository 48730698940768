import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductUid } from '@enums';
import { ProductQueryParamService, ProductService } from '@services';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/@shared/services/product';

@Component({
    selector: 'app-product-select',
    templateUrl: './product-select.component.html',
    styleUrls: ['./product-select.component.css'],
})
export class ProductSelectComponent implements OnInit, OnDestroy {
    @Output() closeMenu = new EventEmitter<void>();

    control = new FormControl(ProductUid.GENESYS_CLOUD_CX);
    options: { text: string; value: string }[];

    private _subs: Subscription[] = [];

    constructor(private productService: ProductService, private productQueryParamService: ProductQueryParamService) {
        this.options = this.productService.availableProducts.map((p) => ({ text: p.displayName, value: p.uid }));
    }

    ngOnInit(): void {
        this._subs.push(
            this.productService.selectedProduct$.subscribe((data) => {
                // NOTE: We have to do this to make the ng-select binding to work
                // cannot do the | async binding to the ngModel
                this.control.setValue(data.uid);
            }),
        );

        this._subs.push(
            this.control.valueChanges.subscribe((selectedUid) => {
                if (selectedUid) {
                    this.productQueryParamService.setProductQueryParam(selectedUid);
                }
            }),
        );
    }

    /**
     * Handle menu close
     */
    handleClosingMenu(): void {
        this.closeMenu.emit();
    }

    /**
     * Add Track By Product to not redraw or
     * re-render list items they have not changed. This improves performance.
     * NOTE: This is using the [trackByFn] function binding to ng-select not the Angular trackBy,
     * so it cannot use our proprietary {@link TrackByItem} API.
     * @param item - Product
     * @returns - Product Uid
     */
    trackByProductUid(item: Product): string {
        return item.uid;
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this._subs) {
            this._subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
