import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturedContentTextOnlyModule } from './featured-content-text-only/featured-content-text-only.module';
import { FeaturedContentThumbnailModule } from './featured-content-thumbnail/featured-content-thumbnail.module';
import { FeaturedContentVideoEmbedModule } from './featured-content-video-embed/featured-content-video-embed.module';
import { FeaturedContentComponent } from './featured-content.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [FeaturedContentComponent],
    imports: [
        CommonModule,
        FeaturedContentTextOnlyModule,
        FeaturedContentThumbnailModule,
        FeaturedContentVideoEmbedModule,
        MatDialogModule,
    ],
    exports: [FeaturedContentComponent],
})
export class FeaturedContentModule {}
