import { Component, OnInit } from '@angular/core';

import { environment } from '@environments/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { DeveloperCenterBlog } from './blog.interface';
import { BlogService } from './blog.service';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
    baseUrl = environment.api.developerCenter.site.baseUrl;
    blogs$: Observable<DeveloperCenterBlog[]>;

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private blogService: BlogService,
    ) {}

    /**
     * Open MUI Modal Dialog with Blog data
     * @param link - Blog
     */
    handleOpenDialog(blog: any) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: blog?.title,
                    src: this.baseUrl.substring(0, this.baseUrl.lastIndexOf('/')) + blog.link,
                    dataAnalytics: 'developer-center-blog',
                },
            });
        });
    }

    ngOnInit(): void {
        this.blogs$ = this.blogService.blogs$;
    }
}
