import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';
import { ProductUid, VideoSource } from '@enums';
import { environment } from '@environments/environment';
import { ProductService, VideoResponse, VimeoService, YouTubeInterface, YoutubeService } from '@services';
import { sortByDate } from '@utils';
import { Subscription, combineLatest, firstValueFrom, map } from 'rxjs';
import { KeplerVimeoResponse } from 'src/app/@shared/services/kepler-vimeo/kepler-vimeo.interface';
import { KeplerVimeoService } from 'src/app/@shared/services/kepler-vimeo/kepler-vimeo.service';

@Component({
    selector: 'app-product-help-videos',
    templateUrl: './product-help-videos.component.html',
    styleUrls: ['./product-help-videos.component.css'],
})
export class VideosComponent implements OnChanges, OnInit, OnDestroy {
    @Input() videosIds: any[];
    @Input() videoSource: VideoSource;
    videos = [];
    private subs: Subscription[] = [];
    baseUrl = environment.videoEmbed.vimeo;
    isMobileView = false;
    desktopVideos: any[] = [];

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        private vimeoService: VimeoService,
        private youtubeService: YoutubeService,
        private vimeoKeplerService: KeplerVimeoService,
        private productService: ProductService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((result: BreakpointState) => {
                this.isMobileView = result.matches;
                this.changeDetector.markForCheck();
            }),
        );
    }

    ngOnChanges(): void {
        if (this.videoSource == VideoSource.VIMEO) {
            this.baseUrl = environment.videoEmbed.vimeo;
            this.getVimeoVideos();
        } else if (this.videoSource == VideoSource.YOUTUBE) {
            this.baseUrl = environment.videoEmbed.youtube;
            this.getYouTubeVideos();
        }
    }

    /**
     * Gets the Vimeo videos.
     */
    private getVimeoVideos(): void {
        this.videos = [];
        this.subs.push(
            this.productService.selectedProduct$.subscribe((product) => {
                if (product.uid !== ProductUid.GENESYS_CLOUD_CX) {
                    if (this.videosIds?.length) {
                        this.subs.push(
                            combineLatest(
                                this.videosIds.map((id: number) => {
                                    return this.vimeoService.getVimeoVideoDetails(id);
                                }),
                            ).subscribe(([...res]) => {
                                this.videos = sortByDate(res, 'upload_date');
                                this.videos.slice(0, 15);
                                this.chunkifyVideos();
                                this.changeDetector.markForCheck();
                            }),
                        );
                    }
                } else {
                    this.subs.push(
                        this.vimeoKeplerService.getVideos('1460832').subscribe((vimeoResponse: KeplerVimeoResponse) => {
                            if (vimeoResponse) {
                                const vimeoVideos = vimeoResponse.data.data.filter((video) => {
                                    return (
                                        video.tags.find((tag) => tag.tag === 'genesys-cloud' || tag.tag === 'gkn') !=
                                        undefined
                                    );
                                });
                                const videoIds = vimeoVideos.map((video) => parseInt(video.uri.split('/')[2]));
                                this.subs.push(
                                    combineLatest(
                                        videoIds.map((id: number) => {
                                            return this.vimeoService.getVimeoVideoDetails(id);
                                        }),
                                    ).subscribe(([...res]) => {
                                        this.videos = sortByDate(res, 'upload_date');
                                        this.videos.slice(0, 15);
                                        this.chunkifyVideos();
                                        this.changeDetector.markForCheck();
                                    }),
                                );
                            }
                        }),
                    );
                }
            }),
        );
    }

    /**
     * Gets the YouTube videos
     */
    private getYouTubeVideos(): void {
        this.subs.push(
            this.youtubeService.getVideoByIds(this.videosIds).subscribe((videos: YouTubeInterface[]) => {
                this.videos = videos?.['items']?.map((item) => {
                    return {
                        title: item.snippet.title,
                        description: item.snippet.description,
                        thumbnail_url: item.snippet.thumbnails.medium.url,
                        video_id: item.id,
                        upload_date: item.snippet.publishedAt,
                    };
                });
                this.videos.slice(0, 15);
                this.chunkifyVideos();
                this.changeDetector.markForCheck();
            }),
        );
    }

    /**
     * Breaks this array into sections
     */
    chunkifyVideos(): void {
        const carouselChunks = [];
        const pageSize = 3;

        for (let i = 0; i < this.videos.length; i += pageSize) {
            carouselChunks.push(this.videos.slice(i, i + pageSize));
        }

        const pageSizeLeftover = this.videos.length % pageSize;
        if (pageSizeLeftover !== 0) {
            for (let i = 0; i < pageSize - pageSizeLeftover; i++) {
                carouselChunks[carouselChunks.length - 1].push(null);
            }
        }

        this.desktopVideos = carouselChunks;
    }

    /**
     * Open MUI Modal Dialog with VideoResponse data
     * @param link - VideoResponse
     */
    handleOpenDialog(video: VideoResponse) {
        firstValueFrom(
            this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state: BreakpointState) => state.matches)),
        ).then((isMobile) => {
            this.dialog.open(IframeDialogComponent, {
                width: '80%',
                height: isMobile ? '' : '80%',
                autoFocus: false,
                data: {
                    title: video?.title,
                    src: this.baseUrl + '/' + video?.video_id,
                    dataAnalytics: 'product-help-videos',
                },
            });
        });
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
