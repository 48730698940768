<app-section-header
    [title]="sectionLabel"
    [favoriteId]="favoriteId"
    [section]="favoriteId"
    [anchors]="anchors"></app-section-header>

<mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" appFilterLocation>
    <mat-tab [label]="filterLabels.ALL"></mat-tab>
    <mat-tab [label]="filterLabels.ANNOUNCEMENTS"></mat-tab>
    <mat-tab [label]="filterLabels.BLOG"></mat-tab>
    <mat-tab [label]="filterLabels.SOCIAL"></mat-tab>
</mat-tab-group>
<app-tile-layout maxColumns="2">
    <li class="news-item" *ngFor="let feed of filteredFeed; trackBy: trackByItem">
        <!--NOTE: Tiles will use custom tiles if the feed requires an article modal (Announcements or Blogs) else it will default as standard generic
            vertical tile for tiles like Social Media, etc...-->
        <app-tile-vertical
            [showImage]="feed.LoadsInFrame || feed.hasSVGIcon ? false : true"
            [imageUrl]="!feed.hasSVGIcon ? feed?.Image : ''"
            [title]="feed?.Title"
            [url]="feed?.Url"
            [titleLink]="true"
            [bigImage]="!feed.hasSVGIcon ? true : false"
            [customImageCss]="'tile-background-color'"
            [isFacebookNews]="feed.Origin == facebookOrigin ? true : false"
            [isCustomGenesysBlogImage]="isCustomGenesysBlogImageUrl(feed)"
            [customSVGIcon]="feed.hasSVGIcon ? feed.Image : ''"
            [hasBoxShadow]="true"
            [icon]="icon"
            [border]="false"
            [vertical]="isMobile$ | async"
            [isCustomTileLink]="feed.LoadsInFrame || feed.hasSVGIcon"
            [isCustomTitleLink]="true"
            [isCustomFooterLink]="true"
            [dataAnalytics]="feed?.dataAnalytics">
            <div customTileLink>
                <!--Custom tiles that open in Article Modal (includes Blogs, Announcements)-->
                <a
                    *ngIf="feed.LoadsInFrame; else nonModalTile"
                    class="cursor primary-link"
                    (click)="handleOpenDialog(feed)"
                    [attr.data-analytics]="getTileDataAnalytics(feed?.dataAnalytics)">
                    <div
                        class="text-align-center tile-border-radius mat-elevation-z6"
                        [ngClass]="{
                            'tile-background-color': feed.hasSVGIcon ? true : false,
                            'tile-background-no-color': feed.hasSVGIcon ? false : true
                        }">
                        <img
                            class="tile-image"
                            [ngClass]="{
                                'big-image': !feed.hasSVGIcon ? true : false,
                                'tile-border-radius': true,
                                'full-width': !feed.hasSVGIcon ? true : false,
                                'custom-source-image': true
                            }"
                            *ngIf="feed?.Image; else defaultImage"
                            [src]="feed?.Image"
                            [alt]="feed?.Title ? feed.Title + ' image' : 'Feed image'" />
                    </div>
                </a>
            </div>
            <ng-template #nonModalTile>
                <!--Else open tile to new tab for any default feed or has custom SVG icon and custom background-->
                <a
                    class="cursor primary-link"
                    [href]="feed?.Url"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    [attr.data-analytics]="getTileDataAnalytics(feed?.dataAnalytics)">
                    <div class="default-image tile-background-color tile-border-radius mat-elevation-z6">
                        <img
                            *ngIf="feed?.Image; else defaultImage"
                            [src]="feed?.Image"
                            [alt]="feed?.AltText"
                            width="52px"
                            height="50px" />
                    </div>
                </a>
            </ng-template>
            <div customTitleLink>
                <span class="tags-date-timestamp-heading">
                    {{ feed?.Published | datesAgo | uppercase }} | {{ feed.Origin | uppercase }}
                </span>
                <div *ngIf="feed.LoadsInFrame; else titleLink">
                    <button
                        class="button-link cursor"
                        (click)="handleOpenDialog(feed)"
                        [attr.data-analytics]="getTitleDataAnalytics(feed?.dataAnalytics)">
                        <h2
                            [innerHTML]="feed?.Title"
                            class="ellipsis-2 sub-title-3 text-brand-navy margin-top-small margin-bottom-smaller"></h2>
                    </button>
                </div>
                <ng-template #titleLink>
                    <a
                        *ngIf="feed?.Title"
                        [href]="feed?.Url"
                        target="_blank"
                        class="cursor primary-link"
                        rel="noopener noreferrer nofollow"
                        [attr.data-analytics]="getTitleDataAnalytics(feed?.dataAnalytics)">
                        <h2
                            [innerHTML]="feed?.Title"
                            class="ellipsis-2 sub-title-3 text-brand-navy margin-top-small margin-bottom-smaller"></h2>
                    </a>
                </ng-template>

                <p class="ellipsis-3 p-body margin-top-smaller">{{ feed?.Content | stripHtml }}</p>

                <div class="external-link-div margin-top-smaller" customFooterLink>
                    <button
                        *ngIf="feed.LoadsInFrame; else footerLink"
                        class="button-link cursor link-n read-more"
                        (click)="handleOpenDialog(feed)"
                        [attr.data-analytics]="getReadMoreDataAnalytics(feed?.dataAnalytics)">
                        Read more
                    </button>
                    <ng-template #footerLink>
                        <a
                            [href]="feed?.Url"
                            target="_blank"
                            class="cursor link-n"
                            rel="noopener noreferrer nofollow"
                            [attr.data-analytics]="getReadMoreDataAnalytics(feed?.dataAnalytics)">
                            Read more
                        </a>
                    </ng-template>
                    <div class="external-icons">
                        <app-share [link]="feed?.Url"></app-share>
                        <app-external-url [url]="feed?.Url"></app-external-url>
                    </div>
                </div>
            </div>
        </app-tile-vertical>
    </li>
</app-tile-layout>
<div class="text-align-center margin-top-large">
    <button
        class="btn btn-secondary padding-left-small padding-right-small"
        *ngIf="showReadMoreButton"
        (click)="loadMore()">
        Load More
        <ng-container *ngTemplateOutlet="customLoadMore"></ng-container>
    </button>
</div>

<ng-template #customLoadMore>
    <ng-container *ngIf="isMobile$ | async; else showLoadMore">
        <app-down-arrow class="margin-left-smaller" [fillColor]="'#3b90aa'"></app-down-arrow>
    </ng-container>
</ng-template>
<ng-template #showLoadMore>
    <app-down-arrow class="margin-left-smaller" [fillColor]="'#ffffff'"></app-down-arrow>
</ng-template>
