import { Component, Input, OnChanges } from '@angular/core';
import { FavoriteId, GetStartedFilterLabel, ProductUid, ResourcesSectionLabel } from '@enums';
import { FavoritesService } from '@services';
@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.css'],
})
export class GetStartedComponent implements OnChanges {
    @Input() selectedProduct: ProductUid;
    @Input() isCssEmployee: boolean = null;

    sectionLabel = ResourcesSectionLabel.GET_STARTED;
    favoriteId: FavoriteId = null;
    getStartedFilterLabel = GetStartedFilterLabel;

    externalUrl = '';

    displayFilters: displayFilters;

    anchors: string[];

    constructor(private favoritesService: FavoritesService) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_STARTER_KIT),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_ONBOARDING),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_SHORT_TRACKS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_USEFUL_LINKS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.GET_STARTED_TIPS_AND_TOOLS),
        ];
    }

    ngOnChanges(): void {
        this.displayFilters = {
            welcomeVideo: true,
            starterKit: true,
            onboardingWebinars: true,
            shortTracks: true,
            usefulLinks: true,
            tipsAndTools: true,
        };

        if (this.isCssEmployee && this.selectedProduct === ProductUid.GENESYS_CLOUD_CX) {
            this.displayFilters.welcomeVideo = true;
            this.favoriteId = FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO;
        } else if (this.isCssEmployee && this.selectedProduct !== ProductUid.GENESYS_CLOUD_CX) {
            this.displayFilters.welcomeVideo = false;
            this.displayFilters.starterKit = false;
            this.displayFilters.onboardingWebinars = false;
            this.displayFilters.shortTracks = false;
            this.favoriteId = FavoriteId.GET_STARTED_STARTER_KIT;
        } else if (!this.isCssEmployee && this.selectedProduct === ProductUid.GENESYS_CLOUD_CX) {
            this.displayFilters.welcomeVideo = false;
            this.favoriteId = FavoriteId.GET_STARTED_STARTER_KIT;
        } else {
            this.displayFilters.welcomeVideo = false;
            this.displayFilters.starterKit = false;
            this.displayFilters.onboardingWebinars = false;
            this.displayFilters.shortTracks = false;
            this.favoriteId = FavoriteId.GET_STARTED_USEFUL_LINKS;
        }
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;
        if (favoriteId == FavoriteId.GET_STARTED_SHORT_TRACKS) {
            this.externalUrl = 'https://vimeo.com/channels/1841991';
        } else if (favoriteId == FavoriteId.GET_STARTED_TIPS_AND_TOOLS) {
            this.externalUrl = 'https://www.genesys.com/resources';
        } else {
            this.externalUrl = '';
        }
    }
}

export interface displayFilters {
    welcomeVideo?: boolean;
    starterKit?: boolean;
    onboardingWebinars?: boolean;
    shortTracks?: boolean;
    usefulLinks?: boolean;
    tipsAndTools?: boolean;
}
