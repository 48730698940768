import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService, ProductService, SectionScrollService, ToastColor, ToastNotificationService } from '@services';
import {
    EMPTY,
    Observable,
    Subscription,
    catchError,
    delay,
    filter,
    firstValueFrom,
    iif,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';

import { FavoriteId } from '@enums';
import { connectWithGenesysTileById } from 'src/app/get-involved/connect-with-genesys/connect-with-genesys.data';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { FavoriteAttributes } from '../../services/favorites/types';

/**
 * Adds the functionality to favorite an item to any component. Favorites are product-specific.
 *
 * Note: requires an entry in {@link FavoriteId} and {@link FavoriteService#mapToLabel} to work properly
 */
@Component({
    selector: 'app-favorite-icon',
    templateUrl: './favorite-icon.component.html',
    styleUrls: ['./favorite-icon.component.css'],
})
export class FavoriteIconComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    /**
     * Allows consumers to specify a custom DOM ID component.
     * By default, the favorite ID is used. This is primarily
     * to allow the Home page to contain the MyFavoritesComponent
     * as well as favorite things lower on the same page. If this
     * is not supplied in such a case, then there could be duplicate
     * DOM IDs and thusly unexpected label/input[type="checkbox"]
     * functionality
     *
     * When no `domIdSuffix` is provided, `FavoriteIconComponent` will assume that its parent component
     * contains the favorite content (based on its `favoriteId`).
     * - only one component is allow to not provide a `domIdSuffix`.
     * - once lazy loading has been implemented in components, using domIdSuffix allows favorite content
     * behavior to match favorite icon id. ie. passing `domId` within url.
     */
    @Input() domIdSuffix: string;
    @Input() favoriteId: FavoriteId;
    @ViewChild('favoriteIconLabel') private favoriteIconLabelElementRef: ElementRef;
    private subscriptions: Subscription[] = [];
    isFavorited = false;
    domId: string;
    @Input() location: FavoriteId;
    @Input() title: string;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private contentService: ContentService,
        private toast: ToastNotificationService,
        private productService: ProductService,
        private route: ActivatedRoute,
        private sectionScrollService: SectionScrollService,
        private favoritesService: FavoritesService,
    ) {}

    ngOnInit(): void {
        if (this.favoriteId) {
            // set component DOM ID.
            this.domId = this.getDomId(this.favoriteId);

            // this.subscriptions.push(
            //     this.sectionScrollService.filterGroupFilterSelect$.subscribe((history: FilterLocationIdHistory) => {
            //         // if we're switching away from this favorite (old location) or to this favorite (new location), update
            //         if (history.oldLocationId == this.realFavoriteId || history.newLocationId == this.realFavoriteId) {
            //             this.determineIfFavorited();
            //         }
            //     }),
            // );
            this.determineIfFavorited();
        }
    }

    ngOnChanges() {
        this.determineIfFavorited();
    }

    /**
     * Checks if current favorite is favorited/selected
     */
    determineIfFavorited(): void {
        this.subscriptions.push(
            this.isFavorited$.subscribe((isFavorited) => {
                this.isFavorited = isFavorited;
                this.changeDetector.markForCheck();
            }),
        );
    }

    ngAfterViewInit(): void {
        this.scrollToFavorite();
    }

    /**
     * When `favoriteId` query parameter matches {@link favoriteId}. The screen will automatically
     * scroll to this component.
     */
    scrollToFavorite(): void {
        this.subscriptions.push(
            this.route.queryParams
                .pipe(
                    map((params) => {
                        const foundFavoriteIdInParams = params.favoriteId === this.favoriteId;
                        return foundFavoriteIdInParams && !this.domIdSuffix;
                    }),
                    // only stream data if truthy: we should scroll to favorites
                    filter((shouldScrollToFavorite) => shouldScrollToFavorite),
                    /**
                     * Delay below:
                     * - This is a temporary fix to scroll after dom content is fetched.
                     *  - {@link ngAfterViewInit} fires once (when dom content  is loaded),
                     *    but this does not included fetched content.
                     * - UX may will not be the same for all users (it mainly depends on internet speed).
                     * - A fix is to load components with all the necessary spacing and fetch content after.
                     * - The `Events` component under `Get Involved` tab, is an example of slowly displaying fetched content,
                     *  and the height changes afterwards.
                     */
                    delay(750),
                )
                .subscribe(() => {
                    const element: HTMLElement = this.favoriteIconLabelElementRef.nativeElement;
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    this.changeDetector.markForCheck();
                }),
        );
    }

    /**
     * create a domId string
     * @param favoriteId favorite id
     * @returns domId to be used
     */
    private getDomId(favoriteId: FavoriteId): string {
        let domId = `favorite-icon-${favoriteId}`;
        const { domIdSuffix } = this;
        // add domIdSuffix if provided
        if (domIdSuffix != undefined && domIdSuffix != null) domId += `-${domIdSuffix}`;
        return domId;
    }

    get isFavorited$(): Observable<boolean> {
        return this.favoritesService.favorites$.pipe(
            map((favorites) => {
                if (!this.favoriteId) return false;
                return favorites.some(({ favoriteId }) => favoriteId == this.realFavoriteId);
            }),
        );
    }

    private get realFavoriteId(): FavoriteId {
        const filter = this.sectionScrollService.currentFilter.get(this.location);
        let favoriteId = this.favoriteId;
        if (filter) {
            favoriteId = filter.locationId as unknown as FavoriteId;
        }
        return favoriteId;
    }

    /** Favorite attributes. */
    private get favoriteAttributes(): Promise<FavoriteAttributes> {
        return firstValueFrom(this.productService.selectedProduct$).then((product) => {
            return { favoriteId: this.realFavoriteId, productId: product.uid };
        });
    }

    /**
     * Toggles favorite icon styles and updates favorites list.
     */
    toggleFavorite(isFavorited: boolean): void {
        this.favoriteAttributes.then((favoriteAttributes) => {
            firstValueFrom(
                this.favoritesService.toggleFavorite(favoriteAttributes, isFavorited).pipe(
                    switchMap((favoritesResponse) =>
                        iif(
                            // if owtgi tile, get label from connect-with-genesys.data.ts file
                            () => favoriteAttributes.favoriteId.startsWith('owtgi'),
                            of(connectWithGenesysTileById(favoriteAttributes.favoriteId)).pipe(
                                map((tile) => [
                                    favoritesResponse,
                                    tile?.title || (favoriteAttributes.favoriteId as string),
                                ]),
                            ),
                            // else map favorite id to static label
                            this.productService.selectedProduct$.pipe(
                                map((selectedProduct) => [
                                    favoritesResponse,
                                    this.favoritesService.mapToLabel(this.realFavoriteId, selectedProduct),
                                ]),
                            ),
                        ),
                    ),
                    tap(([favoritesResponse, label]: [any, string]) => {
                        this.determineIfFavorited();
                        // manual `method` type safety below to make sure all cases are handle (will error if types don't match)
                        const method: 'add' | 'remove' = favoritesResponse.method;
                        const storeSuccess = favoritesResponse.status === 'success';
                        const toastColor = storeSuccess ? ToastColor.INFO : ToastColor.CRITICAL;

                        switch (method) {
                            case 'add': {
                                const actionMsg = storeSuccess ? 'Added' : 'Failed to Add';
                                this.toast.notify({
                                    innerHtml: `${actionMsg} <strong>${label}</strong> to My Favorites.`,
                                    color: toastColor,
                                });
                                break;
                            }
                            default: {
                                const actionMsg = storeSuccess ? 'Removed' : 'Failed to Remove';
                                this.toast.notify({
                                    innerHtml: `${actionMsg} <strong>${label}</strong> from My Favorites.`,
                                    color: toastColor,
                                });
                                break;
                            }
                        }
                    }),
                    catchError((err) => {
                        console.error(`Failed to toggle favorite "${favoriteAttributes.favoriteId}".`, {
                            cause: err,
                        });
                        return EMPTY;
                    }),
                ),
            );
        });
    }

    /** Unsubscribe from all subscriptions. */
    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub?.unsubscribe());
    }
}
