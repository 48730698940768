import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturedContentWysiwygComponent } from './featured-content-wysiwyg.component';

@NgModule({
    declarations: [FeaturedContentWysiwygComponent],
    imports: [CommonModule],
    exports: [FeaturedContentWysiwygComponent],
})
export class FeaturedContentWysiwygModule {}
