<app-section-header
    [title]="sectionLabel"
    [section]="favoriteId"
    [favoriteId]="favoriteId"
    [externalUrl]="externalUrl"
    [anchors]="anchors"></app-section-header>

<app-cx-evolution-excerpt [excerpt]="excerpt$ | async"></app-cx-evolution-excerpt>

<mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" appFilterLocation class="margin-top-small">
    <mat-tab [label]="cxEvolutionFilterLabel.GET_STARTED">
        <app-cx-evolution-get-started></app-cx-evolution-get-started>
    </mat-tab>
    <mat-tab [label]="cxEvolutionFilterLabel.CONNECT">
        <app-cx-evolution-connect></app-cx-evolution-connect>
    </mat-tab>
    <mat-tab [label]="cxEvolutionFilterLabel.LEARN_FROM_OTHERS">
        <app-cx-evolution-learn-from-others></app-cx-evolution-learn-from-others>
    </mat-tab>
</mat-tab-group>
