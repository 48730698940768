<ng-container *ngIf="blogs$ | async; else isLoading">
    <app-tile-layout maxColumns="3">
        <li *ngFor="let blog of blogs$ | async | slice: 0:3">
            <app-tile-vertical
                [showImage]="false"
                [titleLink]="true"
                [description]="blog?.summary"
                [bigImage]="true"
                [details]="blog?.date ? (blog?.date | date: 'mediumDate') + ' | ' + 'BLOG ' : 'BLOG'"
                [isCustomTileLink]="true"
                [isCustomTitleLink]="true"
                [isCustomFooterLink]="true">
                <div customTileLink>
                    <button class="button-link button-image" (click)="handleOpenDialog(blog)">
                        <img
                            [ngClass]="{ 'big-image': bigImage }"
                            class="tile-image"
                            [alt]="title$ | async"
                            *ngIf="blog?.image; else defaultImageLink"
                            [src]="blog?.customImgUrl" />
                    </button>
                    <ng-template #defaultImageLink>
                        <div class="default-image">
                            <img src="/assets/icons/genesys-g.svg" width="28px" height="45px" alt="Genesys logo" />
                        </div>
                    </ng-template>
                </div>
                <div customTitleLink>
                    <button class="button-link cursor" (click)="handleOpenDialog(blog)">
                        <h2
                            class="
                                h3
                                cursor
                                sub-title-3
                                text-brand-navy
                                margin-top-small margin-bottom-smaller
                                title-min-height
                            "
                            >{{ blog.title }}</h2
                        >
                    </button> </div
                >a
                <div customFooterLink>
                    <button class="button-link cursor secondary-link" (click)="handleOpenDialog(blog)"
                        >Read more</button
                    >
                </div>
            </app-tile-vertical>
            {{ imageUrl }}
        </li>
    </app-tile-layout>
</ng-container>

<ng-template #isLoading>
    <app-loading-spinner></app-loading-spinner>
</ng-template>
