<app-section-header
    [title]="sectionLabel"
    [externalUrl]="externalUrl"
    [section]="favoriteId"
    [favoriteId]="favoriteId"></app-section-header>
<app-tile-layout maxColumns="4">
    <li class="li-border" *ngFor="let card of developerCenterMcCxTopics?.cards; trackBy: trackByItem">
        <div class="card cursor">
            <a
                class="primary-link"
                target="_blank"
                rel="noopener noreferrer nofollow"
                [href]="
                    card?.hasAltUrl
                        ? developerCenterMcCxTopics.alternateBaseUrl + card.url
                        : developerCenterMcCxTopics.baseUrl + card.url
                ">
                <img [src]="developerCenterMcCxTopics.iconBase + card.icon" [alt]="card.title + ' icon'" />
                <h2 class="h3 sub-title-1 text-brand-navy margin-top-small margin-bottom-medium">{{ card.title }}</h2>
            </a>
        </div>
    </li>
</app-tile-layout>
