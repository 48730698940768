import { Card } from '../../components/card/card.component';
import { ElearningCoursesService } from '../../services/elearning-courses.service';
import { Marker } from '../../components/marker/marker.interface';
import { Overlay } from '../../components/overlay/overlay.component';
import { OverlayHtmlElement } from '../../components/overlay/overlay-html-element.component';
import { Typography } from '../../components/typography/typography.component';

export class ElearningOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 53, text: 'E-learning' },
        end: { time: 58, text: 'E-learning end' },
    };
    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutLeftBig' });
    }

    html(): HTMLElement {
        const action: HTMLElement = new Typography({
            id: 'elearning-action',
            width: '25%',
            padding: this.width * 0.02 + 'px',
            text: "Here's a course just for you!",
            fontFamily: 'Roboto',
            fontSize: this.width * 0.035 + 'px',
            textAlign: 'right',
        }).html();

        const cardPadding = this.width * 0.015 + 'px';

        const card: HTMLElement = new Card({
            id: 'elearning-card-course',
            width: '20%',
            margin: this.width * 0.015 + 'px',
            padding: `0 ${cardPadding} ${cardPadding} ${cardPadding}`,
            title: {
                text: ElearningCoursesService.STATIC_COURSE.title,
                color: 'white',
                fontSize: this.width * 0.02 + 'px',
            },
            subtitle: {
                text: ElearningCoursesService.STATIC_COURSE.minutes + ' minute self-study',
                color: 'white',
                fontSize: this.width * 0.0125 + 'px',
            },
            link: {
                text: 'Join now!',
                href: ElearningCoursesService.STATIC_COURSE.href,
                fontSize: this.width * 0.0125 + 'px',
            },
        }).html();

        const overlay: HTMLElement = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            hidden: true,
            childNodes: [action, card],
            flexDirection: 'row',
            top: '30%',
        });

        return overlay;
    }
}
